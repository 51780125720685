const WEBSITE_DOMAIN = 'www.braunschweig.de';
const URLS = [
	// ratsinfo* => cms
	{
		ratsinfo: '/bi/kp',
		website: '/politik_verwaltung/politik/ratderstadt/ratsmitglieder.php',
	},
	{
		ratsinfo: '/bi/pa',
		website: '/politik_verwaltung/politik/ratderstadt/ratsmitglieder.php',
	},
	{
		ratsinfo: '/bi/au',
		website: '/politik_verwaltung/politik/ratderstadt/ausschuesse.php',
	},
	{
		ratsinfo: '/bi/to',
		website:
			'/politik_verwaltung/politik/ratderstadt/sitzungsuebersicht.php',
	},
	{
		ratsinfo: '/bi/vo',
		website:
			'/politik_verwaltung/politik/ratderstadt/sitzungsuebersicht.php',
	},
	{
		ratsinfo: '/bi/si',
		website:
			'/politik_verwaltung/politik/ratderstadt/sitzungsuebersicht.php',
	},
	{
		ratsinfo: '/bi/yw',
		website: '/politik_verwaltung/politik/ratderstadt/recherche.php',
	},
	{
		ratsinfo: '/bi/si',
		website: '/politik_verwaltung/politik/ratderstadt/sitzungskalender.php',
	},
];

const url = URLS.find(({ratsinfo}) =>
	window.location.pathname.startsWith(ratsinfo)
);
if (url) {
	const fullLink =
		window.location.protocol + '//' + WEBSITE_DOMAIN + url.website;
	document
		.querySelectorAll('.bs-sidebar-list-item--selected')
		.forEach((element) => {
			element.classList.remove('bs-sidebar-list-item--selected');
		});
	document
		.querySelectorAll('.bs-sidebar-list-item--active')
		.forEach((element) => {
			element.classList.remove('bs-sidebar-list-item--active');
		});

	document
		.querySelectorAll(`.bs-sidebar-list-item__link[href="${fullLink}"]`)
		.forEach((element) => {
			element.parentElement.classList.add(
				'bs-sidebar-list-item--selected'
			);
			element.parentElement.classList.add('bs-sidebar-list-item--active');
		});
}
