import createEventHandlerMatching from '@neonaut/lib-js/es/dom/events/create-event-handler-matching';

const DATA_ATTRIBUTE = 'data-sp-email';
const HREF_ATTRIBUTE = 'href';
const DECRYPTION_EVENT_NAME = 'click';

const ENCRYPTED_AT_REGEX = /%E2%9A%B9/g;
const ENCRYPTED_DOT_REGEX = /%E2%97%A6/g;

function decryptEmailAddress(emailAddress) {
	return emailAddress
		.replace(ENCRYPTED_AT_REGEX, '@')
		.replace(ENCRYPTED_DOT_REGEX, '.');
}

const SELECTOR_LINK = `[${DATA_ATTRIBUTE}]`;

function decryptLink(e, linkElement) {
	linkElement.setAttribute(
		HREF_ATTRIBUTE,
		decryptEmailAddress(linkElement.getAttribute(HREF_ATTRIBUTE))
	);
}

export function render() {
	document.addEventListener(
		DECRYPTION_EVENT_NAME,
		createEventHandlerMatching(SELECTOR_LINK, decryptLink)
	);
}
