import Carousel from './base-carousel';

const SELECTOR_CONTAINER = '.js-bs-intro-stage';

export function render() {
	[...document.querySelectorAll(SELECTOR_CONTAINER)].forEach(
		(containerElement) => {
			Carousel.createInstance(containerElement, {wrapAround: true});
		}
	);
}
