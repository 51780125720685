import {withMediaElement} from '../helpers/mediaelement';
import {translate} from '../helpers/i18n';

let mediaElementInstanceCounter = 0;

function enablePlayer(mediaElement, instanceId) {
	mediaElement.setAttribute('id', instanceId);

	// eslint-disable-next-line no-new
	new window.MediaElementPlayer(instanceId, {
		enableAutosize: true,
		stretching: 'responsive',
		features: mediaElement.classList.contains('bs-audio--with-playlist')
			? [
					'prevtrack',
					'playpause',
					'nexttrack',
					'current',
					'progress',
					'duration',
					'tracks',
					'volume',
					'playlist',
					'fullscreen',
			  ]
			: [
					'playpause',
					'current',
					'progress',
					'duration',
					'tracks',
					'volume',
					'fullscreen',
			  ],
		currentMessage: '',
	});
}

function createPlaceholder(instanceId) {
	return `
		<div class="mejs__container mejs__player mejs__audio" id="${instanceId}" style="height: 100%" tabindex="0" role="application" aria-label="${translate(
		'mejs.audio-player'
	)}">
			<div class="mejs__inner">
				<div class="mejs__mediaelement">
					<div class="mejs__controls">
						<div class="mejs__button mejs__playpause-button mejs__play">
							<button type="button" aria-controls="${instanceId}" title="${translate(
		'mejs.play'
	)}" aria-label="${translate('mejs.play')}" tabindex="0"></button>
						</div>
						<div class="mejs__time mejs__currenttime-container" role="timer" aria-live="off">
							<span class="mejs__currenttime">00:00</span>
						</div>
						<div class="mejs__time-rail">
							<span class="mejs__time-total mejs__time-slider"></span>
						</div>

						<div class="mejs__time mejs__duration-container" style="width: 44px">
							<span class="mejs__duration">&hellip;</span>
						</div>

						<div class="mejs__button mejs__volume-button mejs__mute">
						</div>

						<div class="mejs__horizontal-volume-slider">
							<div class="mejs__horizontal-volume-total">
								<div class="mejs__horizontal-volume-current" style="left: 0; width: 0"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`;
}

function plugin(element) {
	const instanceId = 'bs-audio-' + mediaElementInstanceCounter++;

	const parentNode = element.parentNode;

	const audioElemWrapper = document.createElement('div');
	audioElemWrapper.innerHTML = element.textContent || element.innerHTML;
	const mediaElement = audioElemWrapper.querySelector('.bs-audio');

	if (mediaElement.classList.contains('bs-audio--without-lazyloading')) {
		parentNode.replaceChild(audioElemWrapper, element);
		withMediaElement(() => {
			enablePlayer(mediaElement, instanceId);
		});
		return;
	}

	// Create placeholder
	const placeholderElement = document.createElement('div');
	placeholderElement.classList.add('bs-audio', 'bs-audio--placeholder');
	placeholderElement.innerHTML = createPlaceholder(instanceId);

	parentNode.replaceChild(placeholderElement, element);

	// Load audio assets on first click
	const onClick = () => {
		placeholderElement.classList.add('bs-audio--loading');

		const overlayElement = placeholderElement.querySelector(
			'.js-bs-audio-overlay'
		);
		if (overlayElement) {
			overlayElement.classList.remove('mejs__overlay-play');
			overlayElement.innerHTML = `
				<div class="mejs__overlay-loading">
					<span class="mejs__overlay-loading-bg-img"></span>
				</div>
			`;
		}

		withMediaElement(() => {
			mediaElement.autoplay = true;
			parentNode.replaceChild(audioElemWrapper, placeholderElement);
			enablePlayer(mediaElement, instanceId);
		});
	};

	placeholderElement.addEventListener('click', onClick, {once: true});
}

export function render() {
	document.querySelectorAll('noscript.js-bs-audio').forEach(plugin);
}
