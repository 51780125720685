import {init as initLazyImageLoading} from './modules/lazy-image-loading';
import './modules/paragraph';
import './modules/site-logo';
import './modules/site-intro-minisite-fixed';
import './modules/voice-reader';
import './modules/allris-ratsinfo';
import './modules/scroll-to-top-button';
import './modules/autotranslate';

import {render as renderEmailDecrypt} from './modules/email-decrypt';
import {render as renderCarousel} from './modules/carousel';
import {render as renderCollapsible} from './modules/collapsible';
import {render as renderImageTeaserCarousel} from './modules/image-teaser-carousel';
import {render as renderIntroStage} from './modules/intro-stage';
import {render as renderFigureZoom} from './modules/figure-zoom';
import {render as renderSidebar} from './modules/sidebar';
import {render as renderAudio} from './modules/audio';
import {render as renderVideo} from './modules/video';
import {render as renderYoutube} from './modules/youtube';
import {render as renderIframe} from './modules/iframe';
import {render as renderToggleLocalStorage} from './modules/toggle-local-storage';

renderEmailDecrypt();
renderFigureZoom();
renderCarousel();
renderCollapsible();
renderImageTeaserCarousel();
renderIntroStage();
renderSidebar();
renderAudio();
renderVideo();
renderYoutube();
renderIframe();
renderToggleLocalStorage();

try {
	require('./vendor/sp-common'); // Sitepark code
} catch (error) {
	console.error('Sitepark JS error', error);
}

// init img lazy loading after everything else, allowing custom handling to take place
initLazyImageLoading();
