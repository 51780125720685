/// NOTE: Keep in sync with src/scss/_variables.scss !

const REM_IN_PX = parseFloat(
	window.getComputedStyle(document.documentElement).fontSize
);

export const BREAKPOINTS = {
	desktopAndUp: 1024,
	tableAndUp: 768,
	mobile: [0, 767],
	sidebarFixed: 920,
	sidebarOffCanvas: [0, 919],
	siteMaxWidth: 1440,
};

export const FONT_SIZE_DEFAULT = 1.8 * REM_IN_PX;

export const LINE_HEIGHT_DEFAULT = 26 / 18;

export const SITE_LOGO_SMALL_SIZE = 60;
export const SITE_LOGO_BIG_SIZE = 120;
export const SITE_LOGO_SMALL_BORDER_WIDTH = 20;
export const SITE_LOGO_BIG_BORDER_WIDTH = 40;
export const SITE_LOGO_CONTENT_PADDING = 10;

export const CLASS_NAME_JS_HIDDEN = 'bs--js-hidden';
