import createEventHandlerMatching from '@neonaut/lib-js/es/dom/events/create-event-handler-matching';

import {createLightbox} from './lightbox';
import BaseCarousel from './base-carousel';

const DATA_ATTRIBUTE_INFO = 'data-sp-figure-zoom';
const SELECTOR_CONTAINER = `[${DATA_ATTRIBUTE_INFO}]`;
const SELECTOR_LINK = `${SELECTOR_CONTAINER} a[href]`;
const SELECTOR_LINK_EXCLUDE = 'figcaption a[href]';
const SELECTOR_THUMBNAIL_IMAGE = 'img';

/**
 * @template T
 * @param {T | null | undefined} val val
 * @returns {val is T} is defined
 */
function isDefined(val) {
	return val !== null && val !== undefined;
}

/**
 * @param {HTMLElement} linkElement link elem
 */
export async function zoomFigureByLink(linkElement) {
	const carouselContainerElement = linkElement.closest('.js-bs-carousel');
	const infoElems =
		carouselContainerElement !== null
			? carouselContainerElement.querySelectorAll(SELECTOR_CONTAINER)
			: [linkElement.closest(SELECTOR_CONTAINER)].filter(isDefined);

	const items = Array.from(infoElems)
		.map((infoElement) => {
			const infoStr = infoElement.getAttribute(DATA_ATTRIBUTE_INFO);
			if (infoStr === null) {
				console.error(
					`figure zoom: "${DATA_ATTRIBUTE_INFO}" attr does not exist`,
					infoElement
				);
				return null;
			}

			const itemLinkElement = infoElement.querySelector('a[href]');
			if (itemLinkElement === null) {
				console.error('figure zoom: link element missing', infoElement);
				return null;
			}

			let info;
			try {
				info = JSON.parse(infoStr);
			} catch (e) {
				console.error(
					'figure zoom: invalid json',
					infoElement,
					infoStr
				);
				return null;
			}

			return {
				src: info.src || itemLinkElement.getAttribute('href'),
				msrc: linkElement.querySelector(SELECTOR_THUMBNAIL_IMAGE)?.src,
				width: info.w,
				height: info.h,
				title: renderCaption(info),
				linkElement: itemLinkElement,
				infoElement: infoElement,
				thumbCropped:
					infoElement.parentElement.dataset.cropped === 'true',
			};
		})
		.filter(isDefined);

	const lightbox = await createLightbox(items, {
		index: items.findIndex(
			({linkElement: itemLinkElem}) => itemLinkElem === linkElement
		),
	});
	lightbox.addFilter(
		'thumbEl',
		(thumbEl, data) =>
			thumbEl ||
			data.linkElement.querySelector(SELECTOR_THUMBNAIL_IMAGE) ||
			carouselContainerElement ||
			data.infoElement
	);
	lightbox.init();

	/** @type {import('flickity/js/flickity').Flickity} carousel */
	const carousel =
		carouselContainerElement &&
		BaseCarousel.getInstanceByContainerElement(carouselContainerElement);
	if (carousel) {
		lightbox.on('change', function () {
			const index = lightbox.currSlide.index;
			carousel.select(index);
		});
	}
}

export function renderCaption(info) {
	let caption = '';

	if (info.copyright) {
		caption += `<span class="bs-asset__caption__copyright">&copy;&nbsp;${info.copyright}</span> `;
	}

	if (info.legend) {
		caption += `<span class="bs-asset__caption__text">${info.legend}</span> `;
	}

	/*
	if (info.description) {
		caption += `${info.description} `;
	}
	*/

	return caption;
}

export function render() {
	document.addEventListener(
		'click',
		createEventHandlerMatching(SELECTOR_LINK, (evt, linkElement) => {
			// Ignore non left-button clicks & clicks if there's a mod key pressed at the same time
			if (
				evt.button !== 0 ||
				evt.altKey ||
				evt.ctrlKey ||
				evt.metaKey ||
				evt.shiftKey
			) {
				return;
			}

			if (!linkElement.matches(SELECTOR_LINK_EXCLUDE)) {
				// Did someone else already override the "default" behaviour?
				// Then we do not want to do anything at all.
				if (evt.defaultPrevented) {
					return;
				}

				// Otherwise we handle it and override the browser's default handling
				evt.preventDefault();

				zoomFigureByLink(linkElement);
			}
		})
	);
}
