import A11yDialog from 'a11y-dialog';

import {
	autoTranslate,
	getAvailableLanguages,
	getCurrentLanguage,
	resetPageTranslation,
	translatePage,
} from '../helpers/conword';
import {getDocumentLanguage, translate} from '../helpers/i18n';

const baseLanguageCode = getDocumentLanguage();
const baseLanguageName = {
	de: 'Deutsch',
	en: 'English',
}[baseLanguageCode];

// Make sure we can track piwik events
window._paq = window._paq || [];

function updateDisclaimers() {
	const status = getCurrentLanguage() !== baseLanguageCode;

	document
		.querySelectorAll('.js-bs-translation-disclaimer')
		.forEach((el) =>
			el.setAttribute('aria-hidden', status ? 'false' : 'true')
		);
}

const triggerElements = document.querySelectorAll('.js-bs-auto-translate');
const menu = document.getElementById('bs-auto-translate-menu');
if (!menu) {
	console.error('bs-auto-translate-menu not found');
	triggerElements.forEach((el) => {
		el.remove();
	});
} else {
	(function () {
		const languageCodes = [
			'zh', // Chinesisch
			baseLanguageCode === 'de' ? 'en' : 'de', // Deutsch/Englisch
			'fr', // Französisch
			'it', // Italienisch
			'pl', // Polnisch
			'pt', // Portugiesisch
			'ru', // Russisch
			'es', // Spanisch
			'tr', // Türkisch
			'uk', // Ukrainisch
		].filter((a) => !!a);

		const dialog = new A11yDialog(menu);
		dialog
			.on('show', () => {
				document.body.classList.add('bs-site--no-scroll');
			})
			.on('hide', () => {
				document.body.classList.remove('bs-site--no-scroll');
			});

		const menuContent = menu.querySelector('.js-bs-auto-translate-menu');

		let menuRendered = false;

		autoTranslate(() => {
			updateMenu();
			updateCodes();
			updateDisclaimers();
		});

		function createButton({code, label, onClick}) {
			const tlc = String(code).toUpperCase();
			const button = document.createElement('button');
			button.classList.add('bs-auto-translate-menu__button');
			button.classList.add('cnw_skip_translation');
			button.innerHTML = `
				<span class="bs-auto-translate-menu__button__code">${tlc}</span>
				<span class="bs-auto-translate-menu__button__label" lang="${code}">${label}</span>
			`;
			button.setAttribute('data-bs-auto-translate-lang', code);
			button.addEventListener('click', () => {
				onClick();
				closeDialog();
			});
			return button;
		}

		async function renderMenu() {
			menuRendered = true;
			menu.setAttribute('aria-hidden', dialog.shown ? 'false' : 'true');
			menuContent.innerHTML = `<p>${translate(
				'autotranslate.loading'
			)}</p>`;

			/*
			 * Example response:
			 * {
			 *     "de": {
			 *         "name_en": "German",
			 *         "name": "Deutsch",
			 *         "rtl": false,
			 *         "text_title": "Sprache wählen:",
			 *         "text_1": "Die Seite wird gerade übersetzt …",
			 *         "text_2": "Einen Moment bitte.",
			 *         "text_error": "Beim Übersetzen der Seite ist ein Fehler ist aufgetreten."
			 *     }
			 * }
			 */
			const languages = await getAvailableLanguages();
			const buttons = [];

			buttons.push(
				createButton({
					code: baseLanguageCode,
					label: baseLanguageName,
					onClick: () => {
						resetPageTranslation();
						updateMenu();
						updateCodes();
						updateDisclaimers(false);
					},
				})
			);

			languageCodes.forEach((code) => {
				const language = languages[code];
				if (!language) {
					return;
				}
				buttons.push(
					createButton({
						code,
						label: language.name,
						onClick: () => {
							translatePage(code);
							updateMenu();
							updateCodes();
							updateDisclaimers();
						},
					})
				);
			});

			menuContent.innerHTML = '';
			buttons.forEach((button) => menuContent.appendChild(button));

			updateMenu();
			updateCodes();
		}

		function updateMenu() {
			const currentLanguage = getCurrentLanguage();

			menuContent
				.querySelectorAll('button[data-bs-auto-translate-lang]')
				.forEach((button) => {
					const code = button.getAttribute(
						'data-bs-auto-translate-lang'
					);
					const isActive = code === currentLanguage;
					button.classList[isActive ? 'add' : 'remove'](
						'bs-auto-translate-menu__button--active'
					);
					button.setAttribute(
						'aria-current',
						isActive ? 'true' : undefined
					);
				});
		}

		function closeDialog() {
			dialog?.hide();
			updateButtons();
		}

		function openDialog() {
			// Track open event
			window._paq.push(['trackEvent', 'Conword', 'Open']);

			// Show popup
			dialog?.show();
			updateButtons();
		}

		function updateButtons() {
			document.querySelectorAll('.js-bs-auto-translate').forEach((el) => {
				el.setAttribute(
					'aria-expanded',
					dialog.shown ? 'true' : 'false'
				);
			});
		}

		function updateCodes() {
			document
				.querySelectorAll('.js-bs-auto-translate-code')
				.forEach((el) => {
					el.textContent = getCurrentLanguage().toUpperCase();
				});
		}

		function handleButtonClick() {
			if (!menuRendered) {
				renderMenu().then(() => {
					// Ignore
				});
			}

			if (dialog.shown) {
				closeDialog();
			} else {
				openDialog();
			}
		}

		triggerElements.forEach(function renderAutoTranslateButtons(el) {
			el.setAttribute('aria-haspopup', 'true');
			el.setAttribute('aria-controls', 'bs-auto-translate-menu');
			el.addEventListener('click', () => {
				handleButtonClick();
			});
		});

		updateButtons();
	})();
}
