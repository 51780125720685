import {translate} from '../helpers/i18n';

function plugin(originalElement) {
	const videoId = originalElement.getAttribute('data-bs-youtube-video-id');

	// Activation buttons
	const activationButtonElements = [
		...originalElement.querySelectorAll('.js-bs-youtube-activate-button'),
	];

	const activateVideo = () => {
		activationButtonElements.forEach((el) =>
			el.removeEventListener('click', activateVideo)
		);
		originalElement.classList.remove('bs-video--placeholder');
		originalElement.innerHTML = `
			<iframe width="560" height="315" class="bs-youtube" style="width: 100%; height: 100%"
				src="https://www.youtube-nocookie.com/embed/${encodeURIComponent(
					videoId
				)}?autoplay=1&modestbranding=1&rel=0&showinfo=0" title="${translate(
			'youtube.player'
		)}" frameBorder="0"
				referrerpolicy="no-referrer" sandbox="allow-same-origin allow-scripts allow-presentation" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		`;
	};

	// Load video assets
	activationButtonElements.forEach((el) =>
		el.addEventListener('click', activateVideo)
	);
}

export function render() {
	document.querySelectorAll('.js-bs-youtube').forEach(plugin);
}
