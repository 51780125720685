//import iFrameResize from 'iframe-resizer/js/iframeResizer';

const DEFAULTS = {
	enabled: true,
	//seamless: false,
	//checkOrigin: true,
};
const DATA_ATTRIBUTE_OPTIONS = 'data-sp-iframe';

function addBreakpointListener(mediaQuery, callback) {
	const mql = window.matchMedia(mediaQuery);

	function check(breakpoint) {
		callback(breakpoint.matches);
	}

	mql.addListener(check);
	check(mql); // initial check
}

//function handleResizeEvent(data) {
//	data.iframe.setAttribute('scrolling', 'no');
//}

function setStatus(element, enabled = false) {
	if (enabled) {
		element.classList.remove('bs-iframe--disabled');
		element.classList.add('bs-iframe--enabled');
	} else {
		element.classList.add('bs-iframe--disabled');
		element.classList.remove('bs-iframe--enabled');
	}
}

/**
 * @param {HTMLElement} element element
 */
function plugin(element) {
	//const iframeElement = element.getElementsByTagName('iframe')[0];
	const options = JSON.parse(element.getAttribute(DATA_ATTRIBUTE_OPTIONS));
	const {minWidth, enabled: defaultEnabled} = {...DEFAULTS, ...options}; // , seamless, checkOrigin

	setStatus(element, defaultEnabled);
	if (minWidth) {
		addBreakpointListener(
			`screen and (min-width: ${parseInt(minWidth, 10)}px)`,
			function handleBreakpoint(status) {
				setStatus(element, status);
			}
		);
	}

	//if (seamless) {
	//	iFrameResize({
	//		checkOrigin: checkOrigin,
	//		autoResize: true,
	//		scrolling: true,
	//		onResized: handleResizeEvent,
	//		resizedCallback: handleResizeEvent, // deprecated, for legacy support
	//	}, iframeElement);
	//}
}

export function render() {
	document.querySelectorAll('.js-bs-iframe').forEach(plugin);
}
