import {SITE_LOGO_CONTENT_PADDING} from '../../_variables';

const CLASS_BIG = 'bs-site-logo--big';

export function match(logoElement) {
	return logoElement.classList.contains(CLASS_BIG);
}

export function init(store, logoE, logoContentE, introStageE) {
	const pageContentE = document.querySelector('.bs-content');
	const logoLinkE = document.querySelector('.bs-site-logo__link');
	const logoContentBackgroundE = document.querySelector(
		'.bs-site-logo__content-bg'
	);
	const logoContentContainerE = document.querySelector(
		'.bs-site-logo__content-container'
	);

	return function update() {
		const {
			isScrolled,
			borderWidth,
			contentBgWidth,
			contentBgHeight,
			logoContentRect,
		} = store.getState();

		logoE.classList[isScrolled ? 'add' : 'remove'](
			'bs-site-logo--scrolled'
		);

		// update page content
		if (pageContentE) {
			pageContentE.style.paddingTop =
				introStageE === null ? `${contentBgHeight}px` : '0';
		}

		// update logo content background
		logoContentBackgroundE.style.transform =
			`translate(${borderWidth}px, ${isScrolled ? 0 : borderWidth}px) ` +
			`scale(${contentBgWidth / 100}, ${contentBgHeight / 100})`;

		// update logo content
		if (isScrolled) {
			logoContentContainerE.style.transform = ''; // fall back to css positioning
		} else {
			const left = borderWidth + SITE_LOGO_CONTENT_PADDING;
			const top =
				borderWidth +
				contentBgHeight -
				logoContentRect.height -
				SITE_LOGO_CONTENT_PADDING;
			logoContentContainerE.style.transform = `translate(${left}px, ${top}px)`;
		}

		// update link size
		if (logoLinkE) {
			if (isScrolled) {
				logoLinkE.style.width = '';
				logoLinkE.style.height = '';
			} else {
				logoLinkE.style.width = `${borderWidth + contentBgWidth}px`;
				logoLinkE.style.height = `${borderWidth + contentBgHeight}px`;
			}
		}
	};
}
