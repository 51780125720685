import createEventHandlerMatching from '@neonaut/lib-js/es/dom/events/create-event-handler-matching';

const TOGGLE_CLASS_NAME = 'js-bs-toggle-local-storage';
const BIND_CLASS_NAME = 'js-bs-toggle-local-storage--bind';
const SET_CLASS_NAME = 'js-bs-toggle-local-storage--set';
const DATA_ATTRIBUTE_KEY = 'data-js-bs-toggle-local-storage-key';
const DATA_ATTRIBUTE_VALUE = 'data-js-bs-toggle-local-storage-value';
const DATA_ATTRIBUTE_SET = 'data-js-bs-toggle-local-storage-set';
const LOCAL_STORAGE_PREFIX = 'bs-toggle-local-storage__';

const SELECTOR_ALL_BIND = `.${BIND_CLASS_NAME}[${DATA_ATTRIBUTE_KEY}]`;
const SELECTOR_ALL_SET = `.${SET_CLASS_NAME}[${DATA_ATTRIBUTE_KEY}]`;
const SELECTOR_ALL_TOGGLE = `.${TOGGLE_CLASS_NAME}[${DATA_ATTRIBUTE_KEY}]`;
const SELECTOR_ALL = `${SELECTOR_ALL_BIND}, ${SELECTOR_ALL_SET}, ${SELECTOR_ALL_TOGGLE}`;

const selectorForKey = (key) => `
	.${SET_CLASS_NAME}[${DATA_ATTRIBUTE_KEY}="${key}"],
	.${TOGGLE_CLASS_NAME}[${DATA_ATTRIBUTE_KEY}="${key}"],
	.${BIND_CLASS_NAME}[${DATA_ATTRIBUTE_KEY}="${key}"]
`;

function toggleValue(e, triggerElement) {
	e.stopPropagation();

	const key = triggerElement.getAttribute(DATA_ATTRIBUTE_KEY);
	const value = triggerElement.matches('input[type="checkbox"]')
		? !!triggerElement.checked
		: JSON.parse(triggerElement.getAttribute(DATA_ATTRIBUTE_VALUE));
	const newValue = !value;

	// update local storage for persistence
	localStorage.setItem(
		`${LOCAL_STORAGE_PREFIX}${key}`,
		JSON.stringify(newValue)
	);

	// update elements on page
	updateElements(key, newValue);
}

function setValue(e, triggerElement) {
	e.stopPropagation();

	const key = triggerElement.getAttribute(DATA_ATTRIBUTE_KEY);
	const newValue = JSON.parse(
		triggerElement.getAttribute(DATA_ATTRIBUTE_SET)
	);

	// update local storage for persistence
	localStorage.setItem(
		`${LOCAL_STORAGE_PREFIX}${key}`,
		JSON.stringify(newValue)
	);

	// update elements on page
	updateElements(key, newValue);
}

function updateElements(key, newValue) {
	[...document.querySelectorAll(selectorForKey(key))].forEach(function (
		element
	) {
		element.setAttribute(DATA_ATTRIBUTE_VALUE, JSON.stringify(newValue));

		if (element.matches('input[type="checkbox"]')) {
			element.checked = newValue;
		}
	});
}

const toggleHandler = createEventHandlerMatching(
	SELECTOR_ALL_TOGGLE,
	toggleValue
);
const setHandler = createEventHandlerMatching(SELECTOR_ALL_SET, setValue);

function initElements() {
	const cache = {};

	[...document.querySelectorAll(SELECTOR_ALL)].forEach(function (element) {
		const key = element.getAttribute(DATA_ATTRIBUTE_KEY);
		if (!cache[key]) {
			cache[key] = true;
			updateElements(
				key,
				JSON.parse(
					localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`)
				)
			);
		}

		element.addEventListener('click', toggleHandler);
		element.addEventListener('click', setHandler);
		element.addEventListener('change', toggleHandler);
	});
}

let hasSetGlobalEvents = false;

function initGlobalEvents() {
	if (hasSetGlobalEvents) {
		return;
	}

	hasSetGlobalEvents = true;
	document.addEventListener('click', toggleHandler);
	document.addEventListener('click', setHandler);
	document.addEventListener('change', toggleHandler);
}

export function render() {
	initGlobalEvents();
	initElements();
}
