// noinspection NonAsciiCharacters
export default {
	'mejs.video-player': 'Video player',
	'mejs.audio-player': 'Audio player',
	'mejs.play': 'Play',

	'youtube.player': 'YouTube player',
	'youtube.play': 'Play',

	'lightbox.image': 'The image',
	'lightbox.loading_error': 'could not be loaded.',

	'carousel.next': 'Go to next Element',
	'carousel.prev': 'Go to previous Element',

	'map_layers.osm_contributors': 'OpenStreetMap contributors',
	'map_layers.map': 'Map',
	'map_layers.information': 'Information',
	'map_layers.and': 'and',
	'map_layers.legend': 'Legend',
	'map_layers.map_street': 'Street',
	'map_layers.map_simple': 'Simple',
	'map_layers.map_air': 'Aerial',
	'map_layers.map_bicycle': 'Bicycle',
	'map_layers.map_open_street_map': 'OpenStreetMap',
	'map_layers.user_geolocation': 'Show My Location',
	'map_layers.search_result': 'Search result',

	'autotranslate.loading': 'Loading translations …',
	'autotranslate.disable': 'Disable translation (English)',

	// TODO
	'bswbz.zur': 'Zur',
	'bswbz.am': 'am',
	'bswbz.general_info': 'Allgemeine Informationen zur Briefwahl',
	'bswbz.accessibilities_1': 'Dieses Wahllokal ist rollstuhlgerecht',
	'bswbz.accessibilities_2': 'Dieses Wahllokal ist nicht rollstuhlgerecht',
	'bswbz.accessibilities_3':
		'Dieses Wahllokal ist rollstuhlgerecht mit Hilfe',
	'bswbz.first_vote': 'Erststimme / Direktkandidatinnen und Direktkandidaten',
	'bswbz.candidates': 'Kandidatinnen und Kandidaten',
	'bswbz.number': 'Nr',
	'bswbz.name': 'Name',
	'bswbz.3_votes': 'Sie haben 3 Stimmen',
	'bswbz.second_vote': 'Zweitstimme / Parteien',
	'bswbz.parties': 'Parteien',
	'bswbz.party': 'Partei',
	'bswbz.loading': 'Lade Daten ...',
	'bswbz.error': 'Error',
	'bswbz.constituency': 'Wahlbezirk',
	'bswbz.wahlkreise': 'Wahlkreise',
	'bswbz.stadtbezirk': 'Stadtbezirk',
	'bswbz.gemeindewahlbereich': 'Gemeindewahlbereich',
	'bswbz.landtagswahlkreis': 'Landtagswahlkreis',
	'bswbz.bundestagswahlkreis': 'Bundestagswahlkreis',
	'bsbzw.Strassenbereiche_in_diesem_Wahlbezirk':
		'Straßenbereiche in diesem Wahlbezirk',
	'bsbzw.Straße': 'Straße',
	'bsbzw.ungerade_Nr.': 'ungerade Nr.',
	'bsbzw.gerade_Nr.': 'gerade Nr.',
	'bsbzw.Mein_Wahllokal': 'Mein Wahllokal',
	'bsbzw.Briefwahl': 'Briefwahl',
	'bsbzw.Details_zum_Wahlbezirk': 'Details zum Wahlbezirk',
	'bsbzw.Wer_steht_zur_Wahl?': 'Wer steht zur Wahl?',
	'bsbzw.Straße/Nr.,_Wahlbezirksname_oder_-nummer':
		'Straße/Nr., Wahlbezirksname oder -nummer',
	'bsbzw.new_search': 'Neue Suche',
	'bsbzw.Keine_Wahlbezirke_gefunden': 'Keine Wahlbezirke gefunden.',

	'sidebar.Close Menu': 'Close Menu',
	'sidebar.Close Submenu': 'Close Submenu',
	'sidebar.Close main navigation': 'Close main navigation',
	'sidebar.Open main navigation': 'Open main navigation',
	'sidebar.Jump to main navigation': 'Jump to main navigation',
	'sidebar.Open search': 'Open search',
	'sidebar.Close search': 'Close search',
	'sidebar.Hits by relevance': 'Hits by relevance',
	'sidebar.No hits': 'No hits',
	'sidebar:Error occurred while searching': 'Error occurred while searching',

	'SP.sitekit.search.BraunschweigMapsightSourceSearchController.openFilter':
		'Open filter',
	'SP.sitekit.search.BraunschweigMapsightSourceSearchController.closeFilter':
		'Close filter',
};
