/**
 * Gets the bound client rect as a plain object instead of ClientRect|DomRect to be stored
 *
 * @param {Element} element element
 * @returns {{top: number, left: number, bottom: number, width: number, x, y, right: number, height: number}} bounding client rect of element
 */
export function getBoundingClientRect(element) {
	const {top, right, bottom, left, width, height, x, y} =
		element.getBoundingClientRect();
	return {
		top: top,
		right: right,
		bottom: bottom,
		left: left,
		width: width,
		height: height,
		x: x,
		y: y,
	};
}
