import BaseCarousel from './base-carousel';

const DATA_ATTR_INITED = 'data-js-bs-carousel-inited';
const SELECTOR_CONTAINER = '.js-bs-image-teaser-carousel';

export function render() {
	document.querySelectorAll(SELECTOR_CONTAINER).forEach((elem) => {
		if (elem.getAttribute(DATA_ATTR_INITED) !== 'true') {
			elem.setAttribute(DATA_ATTR_INITED, 'true');

			BaseCarousel.createInstance(elem, {
				adaptiveHeight: true,
				contain: true,
				cellAlign: 'left',
				preloadSlideCount: 4,
				allCellsFocusable: true,
				groupCells: true,
			});
		}
	});
}
