import '@sitepark/sitekit-js/src/main/javascript/ILink';

import '@sitepark/sitekit-js/src/main/javascript/Form';

import '@sitepark/sitekit-js/src/main/javascript/form/field/Field';
import '@sitepark/sitekit-js/src/main/javascript/form/field/FieldGroup';

import '@sitepark/sitekit-js/src/main/javascript/form/field/Checkbox';
import '@sitepark/sitekit-js/src/main/javascript/form/field/CheckboxGroup';
import '@sitepark/sitekit-js/src/main/javascript/form/field/File';
//import '@sitepark/sitekit-js/src/main/javascript/form/field/Date';
import '@sitepark/sitekit-js/src/main/javascript/form/field/Image';
import '@sitepark/sitekit-js/src/main/javascript/form/field/Number';
import '@sitepark/sitekit-js/src/main/javascript/form/field/RadioGroup';
import '@sitepark/sitekit-js/src/main/javascript/form/field/Select';
//import '@sitepark/sitekit-js/src/main/javascript/form/field/Time';
import '@sitepark/sitekit-js/src/main/javascript/form/field/Map';

//import '@sitepark/sitekit-js/src/main/javascript/form/validator/Date';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/Email';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/FileExtension';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/FileMimeType';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/FileSize';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/Number';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/PasswordRepetition';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/RegEx';
import '@sitepark/sitekit-js/src/main/javascript/form/validator/Required';
//import '@sitepark/sitekit-js/src/main/javascript/form/validator/Time';

//import '@sitepark/sitekit-js/src/main/javascript/sectionTypes/*.js';
import '@sitepark/sitekit-js/src/main/javascript/search/Service';
import '@sitepark/sitekit-js/src/main/javascript/search/SearchController';
import '@sitepark/sitekit-js/src/main/javascript/search/SiteSearchController';
import '@sitepark/sitekit-js/src/main/javascript/search/Suggest';
