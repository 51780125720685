import Flickity from 'flickity/js/flickity';

import 'flickity/js/drag';
import 'flickity/js/prev-next-button';
import 'flickity/js/add-remove-cell'; // for cellSizeChange
import {CLASS_NAME_JS_HIDDEN} from '../_variables';
import {translate} from '../helpers/i18n';
import setTabFocusable from '../helpers/set-tab-focusable';

import {
	deferLazyLoadingImages,
	findDeferredLazyLoadingImages,
	findLazyLoadingImages,
	unveilLazyLoadingImages,
} from './lazy-image-loading';

const PRELOAD = 2;
const PRELOAD_INITIAL = PRELOAD;

const DEFAULT_FLICKITY_OPTIONS = {
	cellSelection: '.js-bs-carousel-item',
	contain: true,
	pageDots: false,
	setGallerySize: false,
	adaptiveHeight: false,
	selectedAttraction: 0.2,
	friction: 0.8,
	arrowShape:
		'M26.4 43.74h70.03v12.45h-70.1l36.2 35.69L54.3 100 3.57 50 54.3 0l8.23 8.12z',
};

const carouselRegistry = [];

export default class BaseCarousel extends Flickity {
	constructor(
		containerElement,
		{
			preloadSlideCount = PRELOAD,
			allCellsFocusable = false,
			...options
		} = {}
	) {
		super(containerElement, {
			...DEFAULT_FLICKITY_OPTIONS,
			...options,
			on: {
				...(options.on || {}),
				ready: function () {
					// translate aria labels, add title attribute
					this.prevButton?.element?.setAttribute(
						'aria-label',
						translate('carousel.prev')
					);
					this.nextButton?.element?.setAttribute(
						'aria-label',
						translate('carousel.next')
					);
					this.prevButton?.element?.setAttribute(
						'title',
						translate('carousel.prev')
					);
					this.nextButton?.element?.setAttribute(
						'title',
						translate('carousel.next')
					);

					options.on?.ready?.call(this);

					// noinspection JSPotentiallyInvalidUsageOfClassThis
					this.resize();

					if (allCellsFocusable) {
						this.cells.forEach(({element}, cellIndex) => {
							setTabFocusable(element, true);
							element.addEventListener('focusin', () => {
								this.selectCell(cellIndex, false, false);
							});
						});
					}
				},
			},
		});

		this.registryId = carouselRegistry.length;
		carouselRegistry[this.registryId] = this;
		containerElement.setAttribute(
			'data-bs-carousel-registry-id',
			this.registryId
		);

		this._preloadItemCount = preloadSlideCount;
		this.hasInteracted = false;
		this.on('change', () => {
			this.hasInteracted = true;
			this.selectedElements.forEach((element) => {
				unveilLazyLoadingImages(findDeferredLazyLoadingImages(element));
			});
		});
		this.on('settle', (slideIndex) => this.preloadImages(slideIndex));
		this.on('select', (slideIndex) => {
			this.preloadImages(slideIndex);

			// make only the selected cells focusable
			if (!allCellsFocusable) {
				this.cells.forEach(({element}) => {
					setTabFocusable(
						element,
						this.selectedElements.includes(element)
					);
				});
			}
		});
		this.on('staticClick', this.onClickCell.bind(this));

		this.element.addEventListener('lazyloaded', (event) => {
			try {
				const elem = event.target;
				const img = elem.querySelector('img');
				img.addEventListener('load', () =>
					this.onLazyLoadedImage(event)
				);
			} catch (_) {
				// ignore...
			}
			this.onLazyLoadedImage(event);
		});
	}

	static getInstance(id) {
		return carouselRegistry[id];
	}

	static getInstanceByContainerElement(containerElement) {
		const id = parseInt(
			containerElement.getAttribute('data-bs-carousel-registry-id'),
			10
		);
		return carouselRegistry[id];
	}

	static createInstance(containerElement, options = {}) {
		this.beforeInitialization(containerElement);
		return new this(containerElement, options);
	}

	static beforeInitialization(containerElement) {
		// TODO: use `findAllLazyLoadingImages` instead?
		const lazyLoadingImages = findLazyLoadingImages(containerElement);

		if (lazyLoadingImages.length) {
			deferLazyLoadingImages(lazyLoadingImages.slice(PRELOAD_INITIAL));
			unveilLazyLoadingImages(
				lazyLoadingImages.slice(0, PRELOAD_INITIAL)
			);
		}

		containerElement.classList.remove(CLASS_NAME_JS_HIDDEN);
	}

	onLazyLoadedImage(event) {
		if (!this.hasInteracted) {
			this.resize();
		} else {
			const cell = this.getParentCell(event.target);
			this.cellSizeChange(cell && cell.element);
		}
	}

	beforeResize() {
		// empty
	}

	afterResize() {
		// empty
	}

	resize() {
		if (this.isActive) {
			this.beforeResize();
			super.resize();
			this.afterResize();
		}
	}

	preloadImages(slideIndex) {
		const startI = Math.max(slideIndex - 1 - this._preloadItemCount, 0);
		const endI = slideIndex + 1 + this._preloadItemCount;
		const preloadSlides = this.slides.slice(startI, endI);

		if (preloadSlides.length) {
			preloadSlides.forEach((slide) => {
				slide.cells.forEach((cell) => {
					unveilLazyLoadingImages(
						findDeferredLazyLoadingImages(cell.element)
					);
				});
			});
		}
	}

	onClickCell(_e, _pointer, _cellElement, _cellIndex) {
		// empty
	}
}
