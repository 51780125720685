import {withMediaElement} from '../helpers/mediaelement';
import {translate} from '../helpers/i18n';

let mediaElementInstanceCounter = 0;

function enablePlayer(placeholderElement, videoHtml, instanceId) {
	placeholderElement.classList.remove(
		'bs-video--loading',
		'bs-video--placeholder'
	);
	placeholderElement.classList.add('bs-video--loaded');
	placeholderElement.innerHTML = videoHtml;

	const mediaElement = placeholderElement.querySelector('.bs-video');
	mediaElement.setAttribute('id', instanceId);
	mediaElement.autoplay = true;

	// eslint-disable-next-line no-new
	new window.MediaElementPlayer(instanceId, {
		enableAutosize: true,
		stretching: 'responsive',
		startLanguage: 'de', // TODO: i18n?

		// Video specific
		height: `${(9 / 16) * 100}%`,
	});
}

function createPlaceholder(instanceId, posterUrl) {
	const backgroundStyle = posterUrl
		? `background-color: #333; background-image: url('${posterUrl}')`
		: 'background: #333';

	return `
		<div class="mejs__container mejs__player mejs__video" id="${instanceId}" style="height: 100%" tabindex="0" role="application" aria-label="${translate(
		'mejs.video-player'
	)}">
			<div class="mejs__inner">
				<div class="mejs__mediaelement">
					<div class="[ js-bs-video-layers ] mejs__layers">
						<div class="mejs__poster mejs__layer" style="${backgroundStyle}; width: 100%; height: 100%;"></div>
						<div class="[ js-bs-video-overlay ] mejs__layer [ mejs__overlay mejs__overlay-play ]" style="width: 100%; height: 100%;">
							<button class="mejs__overlay-button" role="button">
								<span class="visuallyhidden">${translate('mejs.play')}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	`;
}

function plugin(originalElement) {
	const instanceId = 'bs-video-' + mediaElementInstanceCounter++;

	// Get data from noscript
	const noscriptElement = originalElement.querySelector('noscript');
	const posterUrl = noscriptElement.getAttribute('data-js-bs-video-poster');
	const videoHtml = noscriptElement.textContent || noscriptElement.innerHTML;

	// Create placeholder
	const placeholderElement = document.createElement('div');
	placeholderElement.classList.add('bs-video', 'bs-video--placeholder');
	placeholderElement.innerHTML = createPlaceholder(instanceId, posterUrl);
	const placeholderLayersElement = placeholderElement.querySelector(
		'.js-bs-video-layers'
	);

	// Copy over optional additional overlay
	const additionalOverlayElement = originalElement.querySelector(
		'.js-bs-video-additional-overlay'
	);
	if (additionalOverlayElement) {
		additionalOverlayElement.addEventListener('click', (e) =>
			e.stopPropagation()
		);
		placeholderLayersElement.appendChild(additionalOverlayElement);
	}

	// Activation buttons
	const activationButtonElements = [
		...placeholderElement.querySelectorAll('.js-bs-video-activate-button'),
	];

	const activateVideo = () => {
		placeholderElement.removeEventListener('click', activateVideo);
		activationButtonElements.forEach((el) =>
			el.removeEventListener('click', activateVideo)
		);
		placeholderElement.classList.add('bs-video--loading');

		const overlayElement = placeholderElement.querySelector(
			'.js-bs-video-overlay'
		);
		if (overlayElement) {
			overlayElement.classList.remove('mejs__overlay-play');
			overlayElement.innerHTML = `
				<div class="mejs__overlay-loading">
					<span class="mejs__overlay-loading-bg-img"></span>
				</div>
			`;
		}

		withMediaElement(() =>
			enablePlayer(placeholderElement, videoHtml, instanceId)
		);
	};

	// Add to dom, remove noscript
	const parentNode = originalElement.parentNode;
	parentNode.insertBefore(placeholderElement, originalElement);
	parentNode.removeChild(originalElement);

	// Load video assets
	placeholderElement.addEventListener('click', activateVideo);
	activationButtonElements.forEach((el) =>
		el.addEventListener('click', activateVideo)
	);
}

export function render() {
	document.querySelectorAll('.js-bs-video').forEach(plugin);
}
