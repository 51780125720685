import throttle from 'lodash/throttle';

import {
	SITE_LOGO_BIG_BORDER_WIDTH,
	SITE_LOGO_BIG_SIZE,
	SITE_LOGO_CONTENT_PADDING,
	SITE_LOGO_SMALL_BORDER_WIDTH,
	SITE_LOGO_SMALL_SIZE,
} from '../../_variables';

import breakpoint from '../../helpers/breakpoint';
import createStore from '../../helpers/createStore';

import {getBoundingClientRect} from './helpers/getBoundingClientRect';
import * as breadcrumb from './breadcrumb';
import * as bigLogo from './big-logo';

const SELECTOR_BASE = '.bs-site-logo';
const SCROLL_AND_RESIZE_THROTTLE = 150; // ms

const noop = () => undefined;

function init(logoE) {
	const siteMainE = document.querySelector('.bs-site-main');
	const introStageE = document.querySelector(
		'.js-bs-intro-stage, .js-bs-intro-image'
	);
	const logoContentE = document.querySelector('.bs-site-logo__content');

	const store = createStore({
		isScrolled: null,
		lastIsScrolled: null,
		logoContentRect: null,
	});

	const updateBreadcrumb = breadcrumb.match(logoE)
		? breadcrumb.init(store)
		: noop;
	const updateLogo = bigLogo.match(logoE)
		? bigLogo.init(store, logoE, logoContentE, introStageE)
		: noop;

	function checkIfLogoFitsInVisibleIntro(isSmall) {
		const borderWidth = isSmall
			? SITE_LOGO_SMALL_BORDER_WIDTH
			: SITE_LOGO_BIG_BORDER_WIDTH;
		const logoSize = isSmall ? SITE_LOGO_SMALL_SIZE : SITE_LOGO_BIG_SIZE;
		const logoHeight =
			calcLogoContentBackgroundHeight(isSmall, borderWidth, logoSize) +
			borderWidth;
		const {bottom: introBottom} = getBoundingClientRect(introStageE);

		return introBottom !== 0 && introBottom < logoHeight;
	}

	function calcLogoContentBackgroundHeight(
		isScrolled,
		borderWidth,
		logoSize
	) {
		const {
			logoContentRect: {width: contentWidth, height: contentHeight},
		} = store.getState();

		const logoHeight = logoSize + borderWidth;
		if (isScrolled) {
			return Math.max(contentHeight, logoHeight);
		}

		const totalHeight = contentHeight + logoHeight;
		return (
			SITE_LOGO_CONTENT_PADDING * 2 + Math.max(contentWidth, totalHeight)
		);
	}

	function handleScrollOrViewportChange({forceUpdate = true} = {}) {
		store.setState({logoContentRect: getBoundingClientRect(logoContentE)});

		const lastIsScrolled = store.getState().isScrolled;
		const isMobile = breakpoint('mobile');
		const isScrolled =
			introStageE === null
				? true
				: checkIfLogoFitsInVisibleIntro(isMobile);
		store.setState({
			lastIsScrolled: lastIsScrolled,
			isScrolled: isScrolled,
		});

		if (forceUpdate || isScrolled !== lastIsScrolled) {
			const isSmall = isScrolled || isMobile;
			const borderWidth = isSmall
				? SITE_LOGO_SMALL_BORDER_WIDTH
				: SITE_LOGO_BIG_BORDER_WIDTH;
			const logoSize = isSmall
				? SITE_LOGO_SMALL_SIZE
				: SITE_LOGO_BIG_SIZE;
			const logoContentBackgroundHeight = calcLogoContentBackgroundHeight(
				isScrolled,
				borderWidth,
				logoSize
			);
			const contentBgHeight = isScrolled
				? logoContentBackgroundHeight + borderWidth
				: logoContentBackgroundHeight;
			const contentBgWidth = isScrolled
				? getBoundingClientRect(siteMainE).width - borderWidth
				: contentBgHeight;

			store.setState({
				borderWidth: borderWidth,
				logoSize: logoSize,
				contentBgWidth: contentBgWidth,
				contentBgHeight: contentBgHeight,
			});

			updateBreadcrumb();
			updateLogo();
		}
	}

	handleScrollOrViewportChange({forceUpdate: true});
	logoE.classList.add('bs-site-logo--inited');

	const handlerThrottled = throttle(
		handleScrollOrViewportChange,
		SCROLL_AND_RESIZE_THROTTLE
	);
	window.addEventListener(
		'resize',
		() => handlerThrottled({forceUpdate: true}),
		{passive: true}
	);

	if (introStageE !== null) {
		window.addEventListener(
			'scroll',
			() => handlerThrottled({forceUpdate: false}),
			{passive: true}
		);
	}

	// We add a class, so we can apply css transitions only after the initial run is done
	requestAnimationFrame(() => {
		logoE.classList.add('bs-site-logo--with-transition');
	});
}

const logoElement = document.querySelector(SELECTOR_BASE);
if (logoElement) {
	init(logoElement);
}
