import {getBoundingClientRect} from './helpers/getBoundingClientRect';

const CLASS_BREADCRUMB = 'bs-site-logo--with-breadcrumb';

export function match(logoElement) {
	return logoElement.classList.contains(CLASS_BREADCRUMB);
}

export function init(store) {
	const logoBreadcrumbListE = document.querySelector(
		'.bs-site-logo__breadcrumb-list'
	);
	const logoBreadcrumbWrapperE = document.querySelector(
		'.bs-site-logo__breadcrumb-wrapper'
	);

	return function update() {
		const {borderWidth, logoSize, contentBgWidth} = store.getState();

		const {width} = getBoundingClientRect(logoBreadcrumbListE);
		const breadcrumbWrapperWidth =
			contentBgWidth - logoSize - borderWidth * 2;
		const isContentBiggerThanContainer = width < breadcrumbWrapperWidth;

		logoBreadcrumbWrapperE.classList[
			isContentBiggerThanContainer ? 'remove' : 'add'
		]('bs-site-logo__breadcrumb-wrapper--has-overflow');
	};
}
