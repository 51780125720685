const SCROLL_THRESHOLD = 300;
const SCROLLED_CLASS = 'bs-scroll-to-top-button--visible';

const elem = document.createElement('a');
elem.setAttribute('class', 'bs-scroll-to-top-button');
elem.setAttribute('href', '#top');
elem.setAttribute('title', 'nach oben');
elem.innerHTML = `
	<svg class="bs-scroll-to-top-button__icon" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 25 22">
		<path d="M14.4565 5.4005v16.529h-2.98V5.3845l-8.544 8.545-1.945-1.944 11.97-11.973 11.972 11.973-1.945 1.944z"/>
	</svg>
`;
document.body.append(elem);

window.addEventListener(
	'scroll',
	() => {
		const isScrolled = window.scrollY > SCROLL_THRESHOLD;

		if (isScrolled) {
			elem.classList.add(SCROLLED_CLASS);
		} else {
			elem.classList.remove(SCROLLED_CLASS);
		}
	},
	{passive: true}
);
