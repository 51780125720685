/**
 * Create a simple store
 *
 * @param {*} [initialState] initial state
 * @returns {{getState: Function, setState: Function}} store
 */
export default function createStore(initialState = {}) {
	let state = initialState;

	return {
		getState: function getState() {
			return state;
		},
		setState: function setState(newState) {
			state = {
				...state,
				...newState,
			};
		},
	};
}
