import {withVoiceReader} from '../helpers/voice-reader';

function handleButtonClick() {
	window._paq = window._paq || [];
	window._paq.push(['trackEvent', 'VoiceReader', 'Open']);

	withVoiceReader(function () {
		// noinspection JSUnresolvedFunction
		if ('___vrweb_embed_player_0' in window) {
			window.___vrweb_embed_player_0();
		} else {
			console.error('Could not load voice reader!');
		}
	});
}

document
	.querySelectorAll('.js-bs-voice-reader')
	.forEach(function renderVoiceReader(buttonElement) {
		buttonElement.addEventListener('click', handleButtonClick);
	});
