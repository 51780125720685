import throttle from 'lodash/throttle';
import passiveEventListener from '@neonaut/lib-js/es/dom/events/passive-event-listener';

import {fetchJsonWithAbort, Headers, Request} from '../../helpers/fetch';
import {translate} from '../../helpers/i18n';

export const DEFAULT_RPC_HEADERS = {
	'content-type': 'application/json;charset=utf-8',
	accept: 'application/json;charset=utf-8',
};

const URL_PREFIX =
	window.location.hostname === 'ratsinfo.braunschweig.de'
		? 'https://www.braunschweig.de'
		: '';
export const DEFAULT_RPC_ENDPOINT_URL =
	URL_PREFIX + '/WEB-IES/sitekit-module/php/SP/SiteKit/Rpc/Server/Port.php';

export const DEFAULT_RPC_PARAMETERS = {
	action: 'SP\\Braunschweig\\Rpc\\Search\\Service',
	method: 'search',
	type: 'rpc',
	tid: 2,
};

export const DEFAULT_RPC_DATA = {
	searchUrl: '/suche.php',
	searchId: 'siteSearch',
	query: '',
};

export function init(instance) {
	const state = {
		currentIndex: 0,
		numberOfResults: 0,
	};

	/* options */
	const rpcEndpointUrl = DEFAULT_RPC_ENDPOINT_URL;
	const rpcHeaders = DEFAULT_RPC_HEADERS;
	const rpcParameters = DEFAULT_RPC_PARAMETERS;
	const rpcData = DEFAULT_RPC_DATA;

	const searchPanelElement = document.getElementById(
		'bs-sidebar-search-panel'
	);
	const formElement = searchPanelElement.querySelector(
		'#bs-sidebar-search-form'
	);
	const inputElement = searchPanelElement.querySelector(
		'#bs-sidebar-search-input'
	);
	const submitButtonElement = searchPanelElement.querySelector(
		'#bs-sidebar-search-button'
	);
	const searchOutputElement = searchPanelElement.querySelector(
		'#bs-sidebar-search-output'
	);

	rpcData.searchUrl = formElement.getAttribute('action') ?? rpcData.searchUrl;

	const searchLinksHtml = searchOutputElement.innerHTML;
	const searchLinksNumberOfItems = searchOutputElement.querySelectorAll(
		'.js-bs-sidebar-search-result-item'
	).length;

	function updateOutput(html) {
		searchOutputElement.innerHTML = html;
		instance.updateFocusableElements(searchPanelElement);
	}

	function renderSearchLinks() {
		state.numberOfResults = searchLinksNumberOfItems;
		return searchLinksHtml;
	}

	function renderEmptyResult() {
		return `
			<h3 class="bs-sidebar-headline">${translate('sidebar.Hits by relevance')}:</h3>
			<p class="bs-sidebar-text">${translate('sidebar.No hits')}</p>
		`;
	}

	function onSearchSuccess(data) {
		if (data.type === 'exception') {
			onSearchError(data.message);
			return;
		}

		const listItems = data.result.data.list;
		state.currentIndex = 0;
		state.numberOfResults = listItems.length;

		if (listItems.length) {
			let i = 0;

			updateOutput(`
				<h3 class="bs-sidebar-headline">${translate('sidebar.Hits by relevance')}:</h3>

				<ul class="bs-sidebar-list bs-sidebar-list--search">
					${listItems
						.map(
							(listItem) => `
						<li class="bs-sidebar-list-item [ bs-theme--${listItem.theme} ]">
							<a
								class="[ js-bs-sidebar-search-result-item ] bs-sidebar-list-item__link"
								data-js-bs-sidebar-search-result-index="${++i}"
								href="${URL_PREFIX}${listItem.url}"
							>
								${listItem.sp_title || listItem.title}
							</a>
						</li>
					`
						)
						.join('')}
				</ul>
			`);
		} else {
			updateOutput(renderEmptyResult());
			inputElement.focus();
		}
	}

	function onSearchError(error) {
		state.currentIndex = 0;
		state.numberOfResults = 0;

		updateOutput(`
			<h3 class="bs-sidebar-headline">${translate(
				'sidebar:Error occurred while searching'
			)}:</h3>
			<p class="bs-sidebar-text">${error}</p>
		`);
	}

	let currentSearch;

	function search() {
		if (currentSearch) {
			currentSearch.abort();
		}

		const query = inputElement.value;
		if (!query.trim()) {
			updateOutput(renderSearchLinks());
			return;
		}

		currentSearch = fetchJsonWithAbort(
			new Request(rpcEndpointUrl, {
				method: 'post',
				headers: new Headers(rpcHeaders),
				body: JSON.stringify({
					...rpcParameters,
					data: [
						{
							...rpcData,
							query: query,
						},
					],
				}),
			}),
			onSearchSuccess,
			onSearchError
		);
	}

	const onKeyUp = throttle(search, 150, {leading: false, trailing: true});
	const onSubmit = (e) => {
		e.stopPropagation();
	};

	inputElement.addEventListener('keyup', onKeyUp, passiveEventListener);
	formElement.addEventListener('submit', onSubmit);
	submitButtonElement.addEventListener('click', onSubmit);

	function moveSelection(direction) {
		const oldIndex = state.currentIndex || 0;
		state.currentIndex =
			(oldIndex + direction) % (state.numberOfResults + 1);
		applyCurrentSelection();
	}

	function applyCurrentSelection() {
		applySelection(findResultItemElementByIndex(state.currentIndex));
	}

	function applySelection(element) {
		// remove class from others
		[
			...searchPanelElement.querySelectorAll(
				'.bs-sidebar-list-item--selected'
			),
		].forEach((resultItemElement) =>
			resultItemElement.classList.remove('bs-sidebar-list-item--selected')
		);
		[
			...searchPanelElement.querySelectorAll(
				'.bs-sidebar-list-item__link--selected'
			),
		].forEach((resultItemElement) =>
			resultItemElement.classList.remove(
				'bs-sidebar-list-item__link--selected'
			)
		);

		if (element) {
			// add class to selected
			element.classList.add('bs-sidebar-list-item__link--selected');
			element.parentNode.classList.add('bs-sidebar-list-item--selected');
			element.focus();
		} else {
			inputElement.focus();
		}
	}

	function findResultItemElementByIndex(index) {
		return searchPanelElement.querySelector(
			`.js-bs-sidebar-search-result-item[data-js-bs-sidebar-search-result-index="${index}"]`
		);
	}

	function makeAction(resultItemElement) {
		resultItemElement.focus();
	}

	function handleActionKeyDown(e) {
		const {keyCode: keyCode = e.which, target} = e;

		// see https://github.com/moroshko/react-autosuggest/pull/388
		if (keyCode === 229) {
			return;
		}

		//e.preventDefault();
		e.stopPropagation();

		const isResultItem = target.matches(
			'.js-bs-sidebar-search-result-item'
		);
		if (isResultItem) {
			makeAction(target);
		}
	}

	function handleDirectionalKeyDown(e, direction) {
		e.preventDefault();
		e.stopPropagation();
		moveSelection(direction);
	}

	searchPanelElement.addEventListener('keydown', (e) => {
		const {key: key = e.char, keyCode: keyCode = e.which} = e;

		switch (keyCode) {
			// Arrow down
			case 40:
				handleDirectionalKeyDown(e, +1);
				break;

			// Arrow up
			case 38:
				handleDirectionalKeyDown(e, -1);
				break;

			// Enter/Space
			case 13:
			case 32:
				handleActionKeyDown(e);
				break;

			// Backspace/Arrow left/Arrow right
			//  -> Move focus to inputElement
			case 8:
			case 37:
			case 39:
				inputElement.focus();
				break;

			// Escape
			//  -> Clear input if not empty, otherwise close sidebar
			case 27:
				if (inputElement.value) {
					e.stopPropagation();
					inputElement.value = '';
					inputElement.focus();
				}
				break;

			default:
				// Looks like a "normal" character/number
				//  -> Move focus to inputElement
				if (!e.altKey && !e.ctrlKey && key.length === 1) {
					inputElement.focus();
				}
		}
	});

	searchPanelElement.addEventListener(
		'focusin',
		(e) => {
			if (e.target.matches('.js-bs-sidebar-search-result-item')) {
				state.currentIndex = parseInt(
					e.target.getAttribute(
						'data-js-bs-sidebar-search-result-index'
					),
					10
				);
			}

			applySelection(e.target);
		},
		passiveEventListener
	);

	instance.searchPanel = {
		openPanel: () => {
			instance.openPanel(searchPanelElement, {animate: false});
			inputElement.focus();
		},
	};
}
