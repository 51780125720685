import BaseCarousel from './base-carousel';
import {registerToggleListener} from './collapsible';

const DATA_ATTR_INITED = 'data-js-bs-carousel-inited';
const SELECTOR_CONTAINER = '.js-bs-carousel';
const SELECTOR_CAPTION = '.bs-asset__caption';

registerToggleListener(function (collapsible) {
	const collapsibleContainer = collapsible.getContentElement();
	if (collapsibleContainer) {
		collapsibleContainer
			.querySelectorAll(
				`${SELECTOR_CONTAINER}[${DATA_ATTR_INITED}="true"]`
			)
			.forEach((elem) => {
				const carousel =
					BaseCarousel.getInstanceByContainerElement(elem);
				if (carousel) {
					carousel.resize();
				}
			});
	}
});

class DynamicAspectCarousel extends BaseCarousel {
	beforeResize() {
		// NOTE: This should be a data attribute, but the CMS can (for now) only output the dimensions as a class
		this.element.querySelectorAll('.js-bs-aspect').forEach((elem) => {
			try {
				const matches = /\bjs-bs-aspect--(\d+)x(\d+)\b/gi.exec(
					elem.className
				);
				if (matches !== null) {
					const ratio =
						parseInt(matches[1], 10) / parseInt(matches[2], 10);
					const w = elem.clientHeight * ratio;
					elem.style.width = `${w}px`;
				}
			} catch (e) {
				console.warn(e);
			}
		});
	}

	afterResize() {
		const viewportWidth = this.viewport.offsetWidth;
		this.cells.forEach((cell) => {
			// align caption with content
			const cellWidth = cell.element.offsetWidth;
			const needsOverride = viewportWidth < cellWidth;

			cell.element
				.querySelectorAll(SELECTOR_CAPTION)
				.forEach((captionElement) => {
					if (needsOverride) {
						captionElement.style.width = `${viewportWidth}px`;
						captionElement.style.marginLeft = `${
							(cellWidth - viewportWidth) / 2
						}px`;
					} else {
						captionElement.style.width = '';
						captionElement.style.marginLeft = '';
					}
				});
		});
	}

	onClickCell(e, pointer, cellElement, cellIndex) {
		// Allow to navigate carousel by clicking on unselected slides
		if (cellIndex !== this.selectedIndex) {
			e.preventDefault();
			e.stopPropagation();

			const direction =
				e.clientX > this.selectedElement.getBoundingClientRect().left
					? 'next'
					: 'previous';
			this[direction]();
		}
	}
}

function renderElement(elem) {
	if (elem.getAttribute(DATA_ATTR_INITED) !== 'true') {
		elem.setAttribute(DATA_ATTR_INITED, 'true');

		const elemOptions = JSON.parse(elem.dataset.jsBsCarouselOptions);

		switch (elemOptions?.styleFormat) {
			case 'smallSquares':
				BaseCarousel.createInstance(elem, {
					contain: true,
					cellAlign: 'center',
					preloadSlideCount: 4,
					allCellsFocusable: true,
					groupCells: true,
					draggable: true,
				});
				break;

			case 'default':
			default:
				DynamicAspectCarousel.createInstance(elem);
		}
	}
}

export function render() {
	document.querySelectorAll(SELECTOR_CONTAINER).forEach((elem) => {
		renderElement(elem);
	});
}
