import throttle from 'lodash/throttle';

const ref = document.querySelector('.js-bs-intro-minisite-fixed__ref');
const fixed = document.querySelector('.bs-intro-minisite-fixed');

function handler() {
	const isScrolled = ref.getBoundingClientRect().bottom < 0;
	fixed.classList[isScrolled ? 'add' : 'remove'](
		'bs-intro-minisite-fixed--visible'
	);
}

if (ref && fixed) {
	window.addEventListener(
		'scroll',
		throttle(handler, 150),
		{passive: true} // IE11: polyfilled by dom4
	);

	handler();
	requestAnimationFrame(() => {
		fixed.classList.add('bs-intro-minisite-fixed--with-transition');
	});
}
