import loadJS from '@neonaut/lib-js/es/dom/network/load-js';

import readSpEnv from './read-sp-env';
import cacheBustUrl from './cacheBustUrl';
import {getDocumentLanguage} from './i18n';

function initMediaElement() {
	window.mejs.i18n.language(getDocumentLanguage());
}

export function isMediaElementLoaded() {
	return !!window.MediaElementPlayer;
}

let loadedPromise = null;

export function withMediaElement(cb) {
	if (loadedPromise === null) {
		loadedPromise = (() => {
			if (isMediaElementLoaded()) {
				initMediaElement();
				return Promise.resolve();
			}
			return new Promise((resolve) => {
				const resourcesPath = readSpEnv('resourcesPath');
				loadJS(
					cacheBustUrl(
						resourcesPath.trim('/') + '/js/chunk-mediaelement.js'
					),
					() => {
						initMediaElement();
						resolve(null);
					}
				);
			});
		})();
	}

	loadedPromise.then(cb);
}
