import passiveEventListener from '@neonaut/lib-js/es/dom/events/passive-event-listener';
import setAriaAttribute from '@neonaut/lib-js/es/dom/access/set-aria-attribute';
import waitForTransitionEnd from '@neonaut/lib-js/es/dom/transition/wait-for-transition-end';
import getViewportWidth from '@neonaut/lib-js/es/dom/access/get-viewport-width';

const SELECTOR_CONTAINER = '.js-bs-collapsible';
const SELECTOR_TRIGGER = '.js-bs-collapsible-trigger';
const SELECTOR_CONTENT = '.js-bs-collapsible-content';
const SELECTOR_CONTENT_WRAPPER = '.js-bs-collapsible-content-wrapper';

const toggleListeners = [];

export function registerToggleListener(fn) {
	toggleListeners.push(fn);
	return () => removeToggleListener(fn);
}

export function removeToggleListener(fn) {
	const index = toggleListeners.indexOf(fn);
	if (index > -1) {
		toggleListeners.splice(index, 1);
	}
}

function triggerToggleListeners(expanded) {
	toggleListeners.forEach((fn) => fn(expanded));
}

const defaultOptions = {
	expandTransitionDuration: 1000,
	collapseTransitionDuration: 1000,
	heightOffset: (transition) => (transition === 'collapse' ? 0 : 80),
	scrollOffset: (transition) =>
		transition === 'collapse' ? 40 : getViewportWidth() > 600 ? 80 : 40, // TODO: Refactor breakpoints
};

export class Collapsible {
	constructor(containerElement, options = {}) {
		this.options = {...defaultOptions, ...options};
		this.containerElement = containerElement;
		this.state = {transitioning: false, ...this.getInitialStateFromDom()};

		this.updateContentElementClasses();
		this.updateAriaAttributes();

		this.containerElement.addEventListener(
			'click',
			this.triggerClickHandler,
			passiveEventListener
		);

		const triggerElement = this.getTriggerElement();
		this.triggerClickHandler = (e) => this.handleTriggerClick(e);
		this.triggerKeyPressHandler = (e) => this.handleTriggerKeyPress(e);
		triggerElement.addEventListener(
			'click',
			this.triggerClickHandler,
			passiveEventListener
		);
		triggerElement.addEventListener(
			'keypress',
			this.triggerKeyPressHandler
		);

		this.windowLocationHashChangeHandler = (e) =>
			this.handleWindowLocationHashChange(e);
		window.addEventListener(
			'hashchange',
			this.windowLocationHashChangeHandler,
			passiveEventListener
		);

		if (this.isLocationHashMatching()) {
			this.scrollContainerElementIntoView();
		}
	}

	static createInstance(containerElement, options = {}) {
		return new Collapsible(containerElement, options);
	}

	getTriggerElement() {
		return this.containerElement.querySelector(SELECTOR_TRIGGER);
	}

	getContentElement() {
		return this.containerElement.querySelector(SELECTOR_CONTENT);
	}

	getContentWrapperElement() {
		return this.containerElement.querySelector(SELECTOR_CONTENT_WRAPPER);
	}

	isLocationHashMatching() {
		const triggerElement = this.getTriggerElement();
		return window.location.hash === `#${triggerElement.getAttribute('id')}`;
	}

	getInitialStateFromDom() {
		return {
			expanded:
				this.isLocationHashMatching() ||
				this.getTriggerElement().getAttribute('aria-expanded') ===
					'true',
		};
	}

	getScrollOffset(transition) {
		return typeof this.options.scrollOffset === 'function'
			? this.options.scrollOffset(transition)
			: +this.options.scrollOffset;
	}

	getHeightOffset(transition) {
		return typeof this.options.heightOffset === 'function'
			? this.options.heightOffset(transition)
			: +this.options.heightOffset;
	}

	scrollContainerElementIntoView() {
		let top =
			(window.scrollY || window.pageYOffset) +
			this.containerElement.getBoundingClientRect().top -
			this.getScrollOffset('expand');
		top = top < 0 ? 0 : top;

		window.scrollTo({top: top, left: 0, behavior: 'smooth'});
	}

	updateContentElementClasses() {
		const isExpanded = this.state.expanded;
		const isTransitioning = this.state.transitioning;
		const contentElement = this.getContentElement();
		contentElement.classList[isTransitioning ? 'add' : 'remove'](
			'bs-collapsible__content--transitioning'
		);
		contentElement.classList[isExpanded ? 'add' : 'remove'](
			'bs-collapsible__content--expanded'
		);
		contentElement.classList[isExpanded ? 'remove' : 'add'](
			'bs-collapsible__content--collapsed'
		);
	}

	updateAriaAttributes() {
		setAriaAttribute(
			this.getTriggerElement(),
			'expanded',
			this.state.expanded ? 'true' : 'false'
		);
		setAriaAttribute(
			this.getContentElement(),
			'hidden',
			this.state.expanded ? 'false' : 'true'
		);
	}

	expand() {
		this.toggle('expand');
	}

	collapse() {
		this.toggle('collapse');
	}

	toggle(transition = null) {
		transition =
			transition || (this.state.expanded ? 'collapse' : 'expand');
		this.state.transitioning = true;
		this.updateContentElementClasses();

		this.state.expanded = transition === 'expand';

		const contentElement = this.getContentElement();
		const contentWrapperElement = this.getContentWrapperElement();

		// Transition
		const height =
			contentWrapperElement.offsetHeight +
			this.getHeightOffset(transition);
		contentElement.style.maxHeight = `${height}px`;

		this.updateAriaAttributes();

		setTimeout(() => {
			this.updateContentElementClasses();
			triggerToggleListeners(this);

			if (transition === 'expand') {
				this.scrollContainerElementIntoView();
			} else {
				window.scrollBy({
					top: -this.getScrollOffset('collapse'),
					left: 0,
					behavior: 'smooth',
				});
			}

			waitForTransitionEnd(
				contentElement,
				() => {
					this.state.transitioning = false;
					contentElement.style.maxHeight = null;
					this.updateContentElementClasses();
				},
				this.options[`${transition}TransitionDuration`]
			);
		}, 20);
	}

	destruct() {
		const triggerElement = this.getTriggerElement();
		triggerElement.removeEventListener(
			'click',
			this.triggerClickHandler,
			passiveEventListener
		);
		triggerElement.removeEventListener(
			'keypress',
			this.triggerKeyPressHandler
		);
		window.removeEventListener(
			'hashchange',
			this.windowLocationHashChangeHandler,
			passiveEventListener
		);
	}

	handleTriggerClick() {
		this.toggle();
	}

	handleTriggerKeyPress(e) {
		if (
			e.keyCode === 13 || // enter
			e.keyCode === 32 // space (bar)
		) {
			e.preventDefault();
			this.toggle();
		}
	}

	handleWindowLocationHashChange() {
		if (this.isLocationHashMatching()) {
			this.expand();
		}
	}
}

export function render() {
	[...document.querySelectorAll(SELECTOR_CONTAINER)].forEach(
		Collapsible.createInstance
	);
}
