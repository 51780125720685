import loadJS from '@neonaut/lib-js/es/dom/network/load-js';

import readSpEnv from '../helpers/read-sp-env';
import cacheBustUrl from '../helpers/cacheBustUrl';

let pswpModule;

function loadPswpModule() {
	if (!pswpModule) {
		pswpModule = new Promise((resolve) => {
			const resourcesPath = readSpEnv('resourcesPath');
			loadJS(
				cacheBustUrl(
					resourcesPath.trim('/') + '/js/chunk-photoswipe.js'
				),
				() => {
					resolve(window.NeonautBraunschweigPhotoswipe);
				}
			);
		});
	}

	return pswpModule;
}

const arrowSVG = `
	<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 82 82" width="82" height="82">
		<defs>
			<filter id="b" width="181.5%" height="181.5%" x="-37%" y="-37%" filterUnits="objectBoundingBox">
				<feOffset dx="2" dy="2" in="SourceAlpha" result="r"/>
				<feGaussianBlur in="r" result="s" stdDeviation="7"/>
				<feComposite in="s" in2="SourceAlpha" operator="out" result="s"/>
				<feColorMatrix in="s" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
			</filter>
			<rect id="a" width="54" height="54" x="0" y="0" rx="27"/>
		</defs>
		<g transform="matrix(-1 0 0 1 70 12)">
			<use xlink:href="#a" fill="#000" filter="url(#b)"/>
			<use xlink:href="#a" fill="#FFF" fill-opacity=".7"/>
		</g>
		<path fill="#4A4A4A" fill-rule="nonzero" d="M34.83 41.1h24.13v-4.23H34.8l12.48-12.14-2.84-2.76-17.48 17 17.48 17 2.84-2.76z"/>
	</svg>
`;

export const DEFAULT_OPTIONS = {
	pswpModule: loadPswpModule,

	arrowPrevSVG: arrowSVG,
	arrowNextSVG: arrowSVG,

	bgOpacity: 1,
	showHideAnimationType: 'zoom',
	showAnimationDuration: 400, // keep in sync with scss
	hideAnimationDuration: 400, // keep in sync with scss
	//errorMessage: `<div class="pswp__error-msg"><a href="%url%" target="_blank">${translate('lightbox.image')}</a> ${translate('lightbox.loading_error')}</div>`,

	closeTitle: 'Close',
	zoomTitle: 'Zoom',
	arrowPrevTitle: 'Previous',
	arrowNextTitle: 'Next',

	errorMsg: 'The image cannot be loaded',
	indexIndicatorSep: ' / ',
};

/**
 * @param {object[]} items items
 * @param {import('photoswipe').Options} options options
 *
 * @returns {import('photoswipe').PhotoSwipeLightbox} photoswipe instance
 */
export async function createLightbox(items = [], options = {}) {
	const PhotoSwipe = await loadPswpModule();

	// Initializes PhotoSwipe lightbox
	/** @type {import('photoswipe/dist/photoswipe.esm.js').PhotoSwipe} lightbox */
	const lightbox = new PhotoSwipe({
		...DEFAULT_OPTIONS,
		...options,
		dataSource: items,
	});
	lightbox.on('uiRegister', function () {
		lightbox.ui.registerElement({
			name: 'custom-caption',
			order: 9,
			isButton: false,
			appendTo: 'root',
			html: '',
			onInit: (el) => {
				lightbox.on('change', () => {
					const title = lightbox.currSlide.data.title;
					el.innerHTML = title
						? `<div class="bs-asset__caption bs-asset__caption--primary">${title}</div>`
						: '';
				});
			},
		});
	});

	return lightbox;
}

export function render() {
	// empty
}
