import throttle from 'lodash/throttle';

import setAriaAttribute from '@neonaut/lib-js/es/dom/access/set-aria-attribute';
import passiveEventListener from '@neonaut/lib-js/es/dom/events/passive-event-listener';
import waitForTransitionEnd from '@neonaut/lib-js/es/dom/transition/wait-for-transition-end';
import childrenWithClass from '@neonaut/lib-js/es/dom/traverse/children-with-class';

import {fetchJsonPromise} from '../../helpers/fetch';
import breakpoint from '../../helpers/breakpoint';
import {translate} from '../../helpers/i18n';
import setTabFocusable from '../../helpers/set-tab-focusable';

import {init as initSearchPanel} from './search-panel';

const SELECTOR_FOCUSABLE =
	'input, select, textarea, button, a[href], .focusable';

const PREFIX = 'bs-sidebar-';
const VIEW_SEARCH = 'search';
const VIEW_MENU = 'menu';
const DEFAULT_VIEW = VIEW_MENU;

const TRANSITION_DURATION = 400;
const TRANSITION_FALLBACK_DURATION = 1.1 * TRANSITION_DURATION;
const ASYNC_DELAY = 25;

let uniqueId = 0;
const p = (a) => PREFIX + a; // add prefix
const pp = (additionalPrefix, a) => `${additionalPrefix}-${PREFIX}${a}`;
const dP = (a) => pp('data', a); // add data prefix
const pId = () => p(uniqueId++);
const affixSelectors = (selectors, pre = '', post = '') =>
	selectors
		.split(',')
		.map((selector) => selector.trim())
		.map((selector) => `${pre}${selector}${post}`)
		.join(',');

export default class Sidebar {
	/** @type {WeakMap<HTMLElement, {last: HTMLElement, last: HTMLElement}>} */
	#focusableElementsInPanelMap;

	/** @type {Array<() => void>} */
	#panelDrops = [];

	constructor(baseElement) {
		this.#focusableElementsInPanelMap = new WeakMap();
		this.baseElement = baseElement;
		this.state = {
			initialized: false,
			opened: false,
			view: undefined,
			id: this.baseElement.getAttribute('id') || pId(),
			lazyLoadedPanels: {},
		};

		this.mobileHeaderElement = document.querySelector(
			`.${p('header-mobile')}`
		);

		this.initBaseElement();
		this.initPageBlocker();

		// open panel of selected menu item OR fall back to the first menu panel
		const panelElement = childrenWithClass(
			this.panelsContainerElement,
			p('panel--opened')
		)[0];
		if (panelElement) {
			this.initialPanelId = panelElement.getAttribute('id');
		}

		initSearchPanel(this);
		this.initGlobalClickListener();

		this.setView(DEFAULT_VIEW);
		this.state.initialized = true;
		updateToggleElements(this.state);

		window.addEventListener(
			'resize',
			throttle(() => {
				this.updateVisibility();
			}, 150),
			{passive: true}
		);
	}

	static render(selector, ...args) {
		return [...document.querySelectorAll(selector)]
			.map((baseElement) => new Sidebar(baseElement, ...args))
			.filter((a) => !!a);
	}

	static #findFirstFocusableItemElement = (panelElement) => {
		// first anchor in *List*
		const firstAnchorInListElement = panelElement.querySelector(
			`.${p('list')} a[href]:not(.hidden)`
		);
		if (firstAnchorInListElement) {
			return firstAnchorInListElement;
		}

		// OR first focusable in *Panel*
		const firstFocusableInPanelElement = panelElement.querySelector(
			affixSelectors(SELECTOR_FOCUSABLE, '', ':not(.hidden)')
		);
		if (firstFocusableInPanelElement) {
			return firstFocusableInPanelElement;
		}

		return undefined;
	};

	initPageBlocker() {
		const pageBlockerElement = document.createElement('div');
		pageBlockerElement.classList.add(p('page-blocker'));
		document.body.appendChild(pageBlockerElement);

		const pageBlockerTouchListener = (e) => {
			e.stopPropagation();
			this.close();
		};

		pageBlockerElement.addEventListener(
			'touchstart',
			pageBlockerTouchListener,
			passiveEventListener
		);
		pageBlockerElement.addEventListener(
			'touchmove',
			pageBlockerTouchListener,
			passiveEventListener
		);

		const pageBlockerMouseListener = (e) => {
			e.preventDefault();
			this.close();
		};
		pageBlockerElement.addEventListener(
			'mousedown',
			pageBlockerMouseListener
		);
	}

	initBaseElement() {
		this.updateVisibility();

		this.panelsContainerElement = this.baseElement.getElementsByClassName(
			p('panels')
		)[0];

		// Open if url hash equals menu id (useful when user clicks the hamburger icon before the menu is created)
		const hash = window.location.hash;
		if (hash && hash.slice(1) === this.state.id) {
			setTimeout(() => this.open(), 1000);
		}

		this.baseElement.addEventListener('scroll', (e) => e.stopPropagation());
		this.baseElement.addEventListener('touchstart', (e) =>
			e.stopPropagation()
		);
		this.baseElement.addEventListener('touchmove', (e) =>
			e.stopPropagation()
		);

		this.baseElement.addEventListener('keydown', (e) => {
			switch (e.keyCode) {
				// Backspace/Arrow left
				//  -> close submenu with
				case 37:
				case 8: {
					this.#openParentPanel();
					break;
				}

				// Escape
				case 27: {
					this.close();
					break;
				}

				// Arrow up
				case 38:
					this.handleArrowUpOrDownKey(e, true);
					break;

				// Arrow right
				case 39:
					this.handleArrowRightKey(e);
					break;

				// Arrow down
				case 40:
					this.handleArrowUpOrDownKey(e, false);
					break;

				// ignore the rest
				default:
			}
		});
	}

	handleArrowUpOrDownKey(e, up) {
		const anchorElement = document.activeElement;
		if (
			!anchorElement ||
			!anchorElement.matches(`#${this.state.id} .${p('list')} a[href]`)
		) {
			return;
		}
		const listElement = anchorElement.closest(`.${p('list')}`);
		if (!listElement) {
			return;
		}

		const anchorsInListElements = [
			...listElement.querySelectorAll('a[href]'),
		];
		const anchorIndex = anchorsInListElements.indexOf(anchorElement);
		const nextIndex = up ? anchorIndex - 1 : anchorIndex + 1;
		if (nextIndex < 0 || nextIndex >= anchorsInListElements.length) {
			return;
		}

		try {
			anchorsInListElements[nextIndex].focus();
		} catch (exception) {
			// ignore
		}
	}

	handleArrowLeftKey(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	handleArrowRightKey(e) {
		if (
			document.activeElement &&
			document.activeElement.matches(
				`#${this.state.id} a[${dP('target')}]`
			)
		) {
			this.handleClickAnchorInSidebar({
				target: document.activeElement,
				preventDefault: () => {
					e.stopPropagation();
					e.preventDefault();
				},
			});
		}
	}

	initGlobalClickListener() {
		document.body.addEventListener('click', (e) => {
			const triggerElement = e.target;

			if (
				triggerElement.matches(
					`#${this.state.id} .${p('panel-bar')} a[${dP('target')}]`
				)
			) {
				this.handleClickAnchorInSidebar(e);
			} else if (
				triggerElement.matches(`#${this.state.id} a[${dP('target')}] i`)
			) {
				this.handleClickNextArrowInSidebar(e);
			} else if (triggerElement.classList.contains(pp('js', 'toggle'))) {
				this.toggle(VIEW_MENU);
			} else if (
				triggerElement.classList.contains(pp('js', 'open-menu'))
			) {
				this.open();
				this.setView(VIEW_MENU);
			} else if (
				triggerElement.classList.contains(pp('js', 'open-search'))
			) {
				this.open();
				this.setView(VIEW_SEARCH);
			} else if (
				triggerElement.classList.contains(pp('js', 'search-toggle'))
			) {
				this.toggle(VIEW_SEARCH);
			}
		});
	}

	handleClickNextArrowInSidebar(e) {
		this.handleClickAnchorInSidebar({
			target: e.target.parentNode,
			preventDefault: () => {
				e.stopPropagation();
				e.preventDefault();
			},
		});
	}

	async handleClickAnchorInSidebar(e) {
		const target = e.target.getAttribute(dP('target'));
		if (!target) {
			return;
		}

		let targetElement = this.panelsContainerElement.querySelector(target);

		// target is not a panel => exit
		if (targetElement && !targetElement.classList.contains(p('panel'))) {
			return;
		}

		// Try to lazy load panel
		if (!targetElement) {
			const panelElement = e.target.closest(
				`.${p('panel')}[${dP('href')}]`
			);
			if (!panelElement) {
				return;
			}

			const url = panelElement.getAttribute(dP('href'));
			if (!url) {
				return;
			}

			e.preventDefault();

			try {
				this.baseElement.classList.add(p('-loading'));

				await this.loadPanel(url);
				targetElement =
					this.panelsContainerElement.querySelector(target);

				// target is not a panel => exit
				if (
					targetElement &&
					!targetElement.classList.contains(p('panel'))
				) {
					throw new Error('lazy loading did not work');
				}
			} catch (exception) {
				console.error(
					'handleClickAnchorInSidebar => error when lazy loading panel',
					url,
					exception
				);
				window.location.href = e.target.href;
				return;
			} finally {
				this.baseElement.classList.remove(p('-loading'));
			}
		} else {
			e.preventDefault();
		}

		// Open panel
		try {
			this.openPanel(targetElement);
		} catch (err) {
			// empty catch
		}
	}

	updateFocusableElements(panelElement) {
		this.#focusableElementsInPanelMap.set(panelElement, {
			first: this.#findFirstFocusablePanelElement(panelElement),
			last: this.#findLastFocusablePanelElement(panelElement),
		});
	}

	#setPanelFocus = (panelElement) => {
		if (!this.state.opened) {
			return;
		}

		this.updateFocusableElements(panelElement);

		if (this.state.view !== VIEW_SEARCH) {
			const firstItemElem =
				Sidebar.#findFirstFocusableItemElement(panelElement) ||
				this.#focusableElementsInPanelMap.get(panelElement).first;
			if (firstItemElem) {
				firstItemElem.focus();
			}
		}

		const focusTrapHandler = (e) => {
			// tab key
			if (this.state.opened && e.keyCode === 9) {
				const {first, last} =
					this.#focusableElementsInPanelMap.get(panelElement);

				if (e.shiftKey) {
					if (document.activeElement === first) {
						e.preventDefault();
						last.focus();
					}
				} else {
					if (document.activeElement === last) {
						e.preventDefault();
						first.focus();
					}
				}
			}
		};

		document.addEventListener('keydown', focusTrapHandler);
		this.#panelDrops.push(() => {
			document.removeEventListener('keydown', focusTrapHandler);
		});
	};

	#findFirstFocusablePanelElement = (panelElement) => {
		// OR first focusable in *Header*th
		const focusableInHeaderElement = panelElement.querySelector(
			affixSelectors(
				SELECTOR_FOCUSABLE,
				`.${p('header')} `,
				':not(.hidden)'
			)
		);
		if (focusableInHeaderElement) {
			return focusableInHeaderElement;
		}

		// first anchor in *List*
		const firstAnchorInListElement = panelElement.querySelector(
			`.${p('list')} a[href]:not(.hidden)`
		);
		if (firstAnchorInListElement) {
			return firstAnchorInListElement;
		}

		// OR first focusable in *Panel*
		const firstFocusableInPanelElement = panelElement.querySelector(
			affixSelectors(SELECTOR_FOCUSABLE, '', ':not(.hidden)')
		);
		if (firstFocusableInPanelElement) {
			return firstFocusableInPanelElement;
		}

		return null;
	};

	#findLastFocusablePanelElement = (panelElement) => {
		// last focusable in *Panel*
		const focusableInPanelElements = panelElement.querySelectorAll(
			affixSelectors(SELECTOR_FOCUSABLE, '', ':not(.hidden)')
		);
		if (focusableInPanelElements.length) {
			return focusableInPanelElements[
				focusableInPanelElements.length - 1
			];
		}

		// OR last anchor in *List*
		const anchorsInListElements = panelElement.querySelectorAll(
			`.${p('list')} a[href]:not(.hidden)`
		);
		if (anchorsInListElements.length) {
			return anchorsInListElements[anchorsInListElements.length - 1];
		}

		// OR last focusable in *Header*th
		const focusableInHeaderElements = panelElement.querySelectorAll(
			affixSelectors(
				SELECTOR_FOCUSABLE,
				`.${p('header')} `,
				':not(.hidden)'
			)
		);
		if (focusableInHeaderElements.length) {
			return focusableInHeaderElements[
				focusableInHeaderElements.length - 1
			];
		}

		return null;
	};

	getOpenedPanelElement() {
		return this.panelsContainerElement.querySelector(
			`.${p('panel--opened')}`
		);
	}

	openPanel(nextPanel, {animate = true} = {}) {
		if (!nextPanel || nextPanel.classList.contains(p('panel--opened'))) {
			return;
		}

		// Open navigation when navigating inside it when initialized
		if (this.state.initialized) {
			this.open();
		}

		const allPanels = childrenWithClass(
			this.panelsContainerElement,
			p('panel')
		);
		const prevOpenedPanels = allPanels.filter((element) =>
			element.classList.contains(p('panel--opened'))
		);

		// Reset old parents
		allPanels
			.filter((element) => element !== nextPanel)
			.forEach((element) =>
				element.classList.remove(p('panel--opened-parent'))
			);

		// Reset old order
		allPanels.forEach((element) =>
			element.classList.remove(p('panel--highest'))
		);

		// Open all logical panel parents
		let parentPanelElement = getParentPanelElement(nextPanel);
		while (parentPanelElement) {
			parentPanelElement.classList.add(p('panel--opened-parent'));
			parentPanelElement = getParentPanelElement(parentPanelElement);
		}

		const openPanelStart = () => {
			// hide prev opened panels
			prevOpenedPanels.forEach((e) =>
				e.classList.remove(p('panel--opened'))
			);

			// open next panel
			nextPanel.classList.add(p('panel--opened'));

			// fix order of panels
			if (nextPanel.classList.contains(p('panel--opened-parent'))) {
				prevOpenedPanels.forEach((e) =>
					e.classList.add(p('panel--highest'))
				);
				nextPanel.classList.remove(p('panel--opened-parent'));
			} else {
				prevOpenedPanels.forEach((e) =>
					e.classList.add(p('panel--opened-parent'))
				);
				nextPanel.classList.add(p('panel--highest'));
			}

			this.updateVisibility();
		};

		const openPanelFinish = () => {
			prevOpenedPanels.forEach((openedPanelElement) => {
				openedPanelElement.classList.remove(p('panel--highest'));
				openedPanelElement.classList.add('hidden');
			});
			nextPanel.classList.remove(p('panel--highest'));

			if (this.state.initialized) {
				this.#setPanelFocus(nextPanel);
			}
		};

		if (!animate) {
			// open without animated transition
			openPanelStart();
			openPanelFinish();
			// Make next panel visible
			nextPanel.classList.remove('hidden');
		} else {
			// Make next panel visible
			nextPanel.classList.remove('hidden');
			// open with animated transition

			// Without the timeout the animation will not work because the element had display: none;
			setTimeout(() => {
				waitForTransitionEnd(
					nextPanel,
					openPanelFinish,
					TRANSITION_FALLBACK_DURATION
				);
				openPanelStart();
			}, ASYNC_DELAY);
		}

		// Lazy load missing sub panels
		setTimeout(async () => {
			try {
				// openPanel => lazy loading sub panel
				await this.loadPanel(nextPanel.getAttribute(dP('href')));
			} catch (exception) {
				console.error(
					'openPanel => lazy loading sub panel error',
					exception
				);
			}
		}, ASYNC_DELAY);
	}

	open() {
		// Already opened -> return
		if (this.state.opened) {
			return;
		}
		this.state.opened = true;

		const scrollBarGap =
			window.innerWidth - document.documentElement.clientWidth;
		document.body.style.paddingRight = `${scrollBarGap}px`;

		// only apply offset to the sidebar if sidebar is fixed;
		// the gap gets disguised by the transition if sidebar isn't fixed
		if (breakpoint('sidebarFixed')) {
			// we need to halve the gap in "siteMaxWidth"-mode bc the sidebar gets centered
			// (positioned by halving the screen width)
			const sidebarRightOffset =
				scrollBarGap * (breakpoint('siteMaxWidth') ? 0.5 : 1.0);
			this.baseElement.style.right = `${sidebarRightOffset}px`;

			if (this.mobileHeaderElement) {
				this.mobileHeaderElement.style.right = `${sidebarRightOffset}px`;
			}
		}

		document.documentElement.classList.add(p('wrapper--is-opening'));
		document.documentElement.classList.add(p('wrapper--is-active'));
		document.documentElement.classList.add(p('wrapper--is-blocking'));

		// Open
		this.baseElement.classList.add(p('-opened'));

		// Without the timeout, the animation won't work because the menu had display: none;
		setTimeout(() => {
			setTimeout(
				() => this.#setPanelFocus(this.getOpenedPanelElement()),
				TRANSITION_FALLBACK_DURATION
			);

			// Opening
			this.updateVisibility();
			document.documentElement.classList.remove(p('wrapper--is-opening'));
			document.documentElement.classList.add(p('wrapper--is-opened'));
		}, ASYNC_DELAY);

		updateToggleElements(this.state);
	}

	close() {
		// Already closed -> return
		if (!this.state.opened) {
			return;
		}

		for (const drop of this.#panelDrops) {
			drop();
		}
		this.#panelDrops = [];

		// Reset view?
		if (this.state.view !== DEFAULT_VIEW) {
			// Reset view
			this.setView(DEFAULT_VIEW);
		} else {
			// Reset menu
			// Reset menu to initial panel
			if (this.initialPanelId) {
				this.openPanel(
					document.getElementById(this.initialPanelId),
					// we only run transitions ("animate") if switching panels on the same view.
					// close is "changing" the view to "default", so if we are already on "default"
					// view we need transitions...
					{animate: true}
				);
			}
		}

		setTimeout(() => {
			this.baseElement.classList.remove(p('-opened'));
			document.documentElement.classList.remove(p('wrapper--is-closing'));
			document.documentElement.classList.remove(
				p('wrapper--is-blocking')
			);
			document.documentElement.classList.remove(p('wrapper--is-active'));

			this.baseElement.style.removeProperty('right');
			document.body.style.removeProperty('padding-right');

			if (this.mobileHeaderElement) {
				this.mobileHeaderElement.style.removeProperty('right');
			}

			this.state.opened = false;
			this.updateVisibility();
		}, TRANSITION_FALLBACK_DURATION);

		// Closing
		document.documentElement.classList.add(p('wrapper--is-closing'));
		document.documentElement.classList.remove(p('wrapper--is-opened'));

		this.state.opened = false;

		updateToggleElements({...this.state, opened: false});

		// move focus to main content
		const siteMainContentElement = document.getElementById('bs-site-main');
		if (siteMainContentElement) {
			const url = location.href;
			location.href = '#bs-site-main';
			if (window.history) {
				window.history.replaceState(null, null, url);
			}
		}
	}

	#openParentPanel() {
		const parentPanelElement = getParentPanelElement(
			this.getOpenedPanelElement()
		);
		if (parentPanelElement) {
			this.openPanel(parentPanelElement);
		}
	}

	setView(nextViewName) {
		const previousViewName = this.state.view;
		if (previousViewName === nextViewName) {
			// setView', nextViewName, ' skipped
			return;
		}

		this.state.view = nextViewName;

		this.baseElement.classList.add(p(`-view-${nextViewName}`));
		document.documentElement.classList.add(
			p(`wrapper--view-${nextViewName}`)
		);

		if (previousViewName) {
			this.baseElement.classList.remove(p(`-view-${previousViewName}`));
			document.documentElement.classList.remove(
				p(`wrapper--view-${previousViewName}`)
			);

			if (nextViewName === VIEW_SEARCH) {
				this.searchPanel.openPanel();
			} else {
				if (this.initialPanelId) {
					this.openPanel(
						document.getElementById(this.initialPanelId),
						{animate: false}
					);
				}
			}
		}

		updateToggleElements(this.state);
	}

	toggle(nextViewName = DEFAULT_VIEW) {
		if (nextViewName === this.state.view && this.state.opened) {
			this.close();
		} else {
			this.setView(nextViewName);
			this.open();
		}
	}

	async loadPanel(url) {
		if (!url) {
			throw new Error('missing url');
		}

		if (this.state.lazyLoadedPanels[url] === true) {
			// already done
			return undefined;
		}

		if (this.state.lazyLoadedPanels[url]) {
			// wait for pending load; return the existing promise
			return this.state.lazyLoadedPanels[url];
		}

		// load panel
		const promise = (async () => {
			try {
				const panels = await lazyLoadPanels(url);

				Object.keys(panels)
					.filter((id) => !document.getElementById(id))
					.map((id) => panels[id])
					.forEach((html) =>
						this.panelsContainerElement.insertAdjacentHTML(
							'beforeend',
							html
						)
					);

				this.state.lazyLoadedPanels[url] = true;

				// load panel resolved
				return undefined;
			} catch (e) {
				console.error('error while lazy loading panel', e);
				throw new Error('error loading'); // why re-throw a "stripped-down" error?
			}
		})();

		// store for later use
		this.state.lazyLoadedPanels[url] = promise;

		return promise;
	}

	updateVisibility() {
		const sidebarIsVisible =
			this.state.opened || breakpoint('sidebarFixed');
		setAriaAttribute(this.baseElement, 'hidden', !sidebarIsVisible);
		const allPanels = childrenWithClass(
			this.panelsContainerElement,
			p('panel')
		);
		allPanels.forEach((e) => {
			const isVisible =
				sidebarIsVisible && e.classList.contains(p('panel--opened'));
			setAriaAttribute(e, 'hidden', !isVisible);
			setTabFocusable(e, isVisible);
		});
	}
}

function updateToggleElements({opened, view, id}) {
	const isMenuExpanded = opened && view === VIEW_MENU;
	updateToggleElement(
		pp('js', 'toggle'),
		isMenuExpanded,
		translate(
			isMenuExpanded
				? 'sidebar.Close main navigation'
				: 'sidebar.Open main navigation'
		),
		id
	);
	updateToggleElement(
		pp('js', 'open-menu'),
		isMenuExpanded,
		translate(
			isMenuExpanded
				? 'sidebar.Jump to main navigation'
				: 'sidebar.Open main navigation'
		),
		id
	);

	const isSearchExpanded = opened && view === VIEW_SEARCH;
	updateToggleElement(
		pp('js', 'search-toggle'),
		isSearchExpanded,
		translate(
			isSearchExpanded ? 'sidebar.Close search' : 'sidebar.Open search'
		),
		id
	);
}

function updateToggleElement(classNameBase, expanded, label, id) {
	[...document.getElementsByClassName(classNameBase)].forEach((element) => {
		element.classList[expanded ? 'add' : 'remove'](
			`${classNameBase}--active`
		);
		setAriaAttribute(element, 'haspopup', 'true');
		setAriaAttribute(element, 'controls', id);
		setAriaAttribute(element, 'expanded', expanded ? 'true' : 'false');
		setAriaAttribute(element, 'label', label);
	});
}

function getParentPanelElement(panelElement = null) {
	if (!panelElement) {
		return null;
	}

	return document.getElementById(panelElement.getAttribute(dP('parent')));
}

function lazyLoadPanels(url) {
	const lazyLoadUrl = url + '?lazy-load-sidebar';
	return fetchJsonPromise(lazyLoadUrl).then((json) => json.data.panels);
}
