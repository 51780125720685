import loadJS from '@neonaut/lib-js/es/dom/network/load-js';

import {getDocumentLanguage} from './i18n';

// Make sure we can track piwik events
window._paq = window._paq || [];

let loadedPromise = null;

const baseLanguage = getDocumentLanguage();
const clientId = 'braunschweig';

export function autoTranslate(cb) {
	if (hasCownwordConfig()) {
		withConword(() => {
			window._paq.push([
				'trackEvent',
				'Conword',
				'AutoTranslate',
				getCurrentLanguage(),
			]);

			if (cb) {
				cb();
			}
		});
	}
}

export function hasCownwordConfig() {
	return localStorage?.getItem('cnw_config') !== null;
}

export function getCurrentLanguage() {
	return window.Conword.get_current_language();
}

export function translatePage(code) {
	window._paq.push(['trackEvent', 'Conword', 'Translate', code]);
	window.Conword.translate(code);
}

export function resetPageTranslation() {
	window._paq.push(['trackEvent', 'Conword', 'Translate', 'disable']);
	window.Conword.translate(baseLanguage);
	window.Conword.unset_user_language();
}

export async function getAvailableLanguages() {
	return new Promise((resolve) => {
		withConword(() => {
			const languages = window.Conword.get_available_languages();
			resolve(languages);
		});
	});
}

export function withConword(cb) {
	if (loadedPromise === null) {
		loadedPromise = new Promise((resolve, reject) => {
			document.addEventListener('Conword:ready', () => {
				resolve();
			});

			loadJS(
				`https://static.conword.io/js/v2/${encodeURIComponent(
					clientId
				)}/conword.js`,
				() => {
					// We need to wait for async jQuery.ready() inside the chunk, before we are ready
					if (!window.Conword) {
						console.error('conword global missing');
						reject('could not load conword script');
					}
				}
			);
		});
	}

	loadedPromise.then(cb);
}
