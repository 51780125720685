// noinspection NonAsciiCharacters
export default {
	'mejs.video-player': 'Video-Player',
	'mejs.audio-player': 'Audio-Player',
	'mejs.play': 'Abspielen',

	'youtube.player': 'YouTube-Player',
	'youtube.play': 'Abspielen',

	'lightbox.image': 'Das Bild',
	'lightbox.loading_error': 'konnte nicht geladen werden.',

	'carousel.next': 'Gehe zum nächsten Element',
	'carousel.prev': 'Gehe zum vorherigen Element',

	'map_layers.osm_contributors': 'OpenStreetMap-Mitwirkende',
	'map_layers.map': 'Karte',
	'map_layers.information': 'Information',
	'map_layers.and': 'und',
	'map_layers.legend': 'Legende', // FIXME das wird nun von @mapsight/ui gebraucht. dh. i18n.js muss ein npm werden, bei dem Sprachendaten registriert werden
	'map_layers.map_street': 'Straße',
	'map_layers.map_simple': 'Vereinfacht',
	'map_layers.map_air': 'Luftbild',
	'map_layers.map_bicycle': 'Fahrrad',
	'map_layers.map_open_street_map': 'OpenStreetMap',
	'map_layers.user_geolocation': 'Eigener Standort',
	'map_layers.search_result': 'Suchergebnisse',

	'autotranslate.loading': 'Lädt Übersetzungen …',

	//
	'bswbz.zur': 'Zur',
	'bswbz.am': 'am',
	'bswbz.general_info': 'Allgemeine Informationen zur Briefwahl',
	'bswbz.accessibilities_1': 'Dieses Wahllokal ist rollstuhlgerecht',
	'bswbz.accessibilities_2': 'Dieses Wahllokal ist nicht rollstuhlgerecht',
	'bswbz.accessibilities_3':
		'Dieses Wahllokal ist rollstuhlgerecht mit Hilfe',
	'bswbz.first_vote': 'Erststimme / Direktkandidatinnen und Direktkandidaten',
	'bswbz.candidates': 'Kandidatinnen und Kandidaten',
	'bswbz.number': 'Nr',
	'bswbz.name': 'Name',
	'bswbz.3_votes': 'Sie haben 3 Stimmen',
	'bswbz.second_vote': 'Zweitstimme / Parteien',
	'bswbz.parties': 'Parteien',
	'bswbz.party': 'Partei',
	'bswbz.loading': 'Lade Daten ...',
	'bswbz.error': 'Error',
	'bswbz.constituency': 'Wahlbezirk',
	'bswbz.wahlkreise': 'Wahlkreise',
	'bswbz.stadtbezirk': 'Stadtbezirk',
	'bswbz.gemeindewahlbereich': 'Gemeindewahlbereich',
	'bswbz.landtagswahlkreis': 'Landtagswahlkreis',
	'bswbz.bundestagswahlkreis': 'Bundestagswahlkreis',
	'bsbzw.Strassenbereiche_in_diesem_Wahlbezirk':
		'Straßenbereiche in diesem Wahlbezirk',
	'bsbzw.Straße': 'Straße',
	'bsbzw.ungerade_Nr.': 'ungerade Nr.',
	'bsbzw.gerade_Nr.': 'gerade Nr.',
	'bsbzw.Mein_Wahllokal': 'Mein Wahllokal',
	'bsbzw.Briefwahl': 'Briefwahl',
	'bsbzw.Details_zum_Wahlbezirk': 'Details zum Wahlbezirk',
	'bsbzw.Wer_steht_zur_Wahl?': 'Wer steht zur Wahl?',
	'bsbzw.Straße/Nr.,_Wahlbezirksname_oder_-nummer':
		'Straße/Nr., Wahlbezirksname oder -nummer',
	'bsbzw.new_search': 'Neue Suche',
	'bsbzw.Keine_Wahlbezirke_gefunden': 'Keine Wahlbezirke gefunden.',

	'sidebar.Close Menu': 'Menü schließen',
	'sidebar.Close Submenu': 'Untermenü schließen',
	'sidebar.Close main navigation': 'Hauptmenü schließen',
	'sidebar.Open main navigation': 'Hauptmenü öffnen',
	'sidebar.Jump to main navigation': 'Zum Hauptmenü springen',
	'sidebar.Open search': 'Suche öffnen',
	'sidebar.Close search': 'Suche schließen',
	'sidebar.Hits by relevance': 'Treffer nach Relevanz',
	'sidebar.No hits': 'Keine Treffer',
	'sidebar:Error occurred while searching': 'Fehler bei der Suche',

	'SP.sitekit.search.BraunschweigMapsightSourceSearchController.openFilter':
		'Filter öffnen',
	'SP.sitekit.search.BraunschweigMapsightSourceSearchController.closeFilter':
		'Filter schließen',
};
